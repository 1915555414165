import React from "react";
import SVGFB from "../../assets/SVG/SVGFB";
import SVGX from "../../assets/SVG/SVGX";
import SVGInsta from "../../assets/SVG/SVGInsta";
import faanglogo from "../../assets/faang_white_logo.png";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { FaDiscord, FaLinkedin, FaMapMarkerAlt, FaTwitter } from "react-icons/fa";

const Footer = () => {
  const navigate = useNavigate();

  return (
    <div className="w-full h-auto mt-12 bg-slate-950 flex flex-col shadow-custom-shadow-4">
      <div className="p-10 w-full max-sm:p-4 text-gray-100">

        {/* Main Content Wrapper */}
        <div className="flex flex-col space-y-8">
          <div className="flex justify-between w-full space-x-12 max-sm:flex-col max-sm:space-x-0 max-sm:space-y-6">
            {/* Left Column */}
            <div className="flex flex-col w-full space-y-6">
              {/* Logo Section */}
              <div
                className="flex items-center cursor-pointer"
                onClick={() => navigate("/")}
              >
                <img
                  src={faanglogo}
                  alt="Faang Logo"
                  className="max-sm:h-8 h-10 object-contain"
                />
              </div>

              {/* Address Section */}
              <div className="flex items-center text-sm text-gray-400 leading-relaxed">
                <FaMapMarkerAlt className="text-gray-500 h-5 w-5 mr-2" />
                <span>
                  251 Little Falls Drive, City of Wilmington,<br />
                  County of New Castle, 19808
                </span>
              </div>


              {/* Description Section */}
              {/* <div className="text-sm text-gray-400 leading-relaxed">
                Create personalized interview experiences that adapt to your unique style and needs. It empowers you to design faster than ever.
              </div> */}

              {/* Social Media Links */}
              <div className="flex space-x-6">
                <a
                  href="https://www.linkedin.com/company/faanginc/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-400 cursor-pointer hover:text-gray-300"
                >
                  <FaLinkedin className="text-white text-[27px]" />
                </a>
                <a
                  href="https://discord.gg/yTEAEqXqJQ"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-400 cursor-pointer hover:text-gray-300"
                >
                  <FaDiscord className="text-white text-[27px]" />
                </a>
                <a
                  href=""
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-400 cursor-pointer hover:text-gray-300"
                >
                  <FaTwitter className="text-white text-[27px]" />
                </a>

              </div>
            </div>

            {/* Right Column - Links */}
            <div className="flex w-full justify-end space-x-12 max-sm:space-x-8 max-sm:justify-start">
              <div className="flex flex-col space-y-2 text-sm">
                <Link to="/templates" className="hover:text-white">
                  Buddy Templates
                </Link>
                <Link to="/blogs" className="hover:text-white">
                  Blog Post
                </Link>
                <Link to="/faang-team" className="hover:text-white">
                  FAANG Team
                </Link>
              </div>
              <div className="flex flex-col space-y-2 text-sm">
                <Link to="/aboutus" className="hover:text-white">
                  About Us
                </Link>
                <Link to="/privacy-policy" className="hover:text-white">
                  Privacy Policy
                </Link>
              </div>
            </div>
          </div>

          {/* Divider */}
          <hr className="border-gray-700" />

          {/* Footer Section */}
          <div className="flex justify-between items-center text-sm max-sm:text-xs">
            <span className="text-gray-400">
              &#169; {new Date().getFullYear()} Faang. All Rights Reserved.
            </span>
            <Link to="/terms-of-use" className="text-gray-400 hover:text-white">
              Terms & Conditions
            </Link>
          </div>
        </div>
      </div>


    </div>
  );
};

export default Footer;
