import React, { useEffect, useRef } from "react";
import { motion } from "framer-motion";

const TestimonialCarousel = () => {
  const row1Ref = useRef(null);
  const row2Ref = useRef(null);

  const reviews = [
    {
      rating: "⭐⭐⭐⭐⭐",
      text: "Buddy puts me in real-life interview conditions, helping me identify and work on weaknesses. The detailed feedback is amazing!",
      name: "Ugo Meli",
      position: "Statistical Modeling Student at Université Paris Dauphine - PSL",
      img: "https://peppy-khapse-54c09c.netlify.app/Ugo_Meli.png",
      imgSize: "40px",
    },
    {
      rating: "⭐⭐⭐⭐⭐",
      text: "Coffee Chat is like talking to a mentor. It helped me decide my next steps and solve ongoing problems.",
      name: "Carter Springall",
      position: "CTO & Co-founder @ Artifact AI",
      img: "https://peppy-khapse-54c09c.netlify.app/Carter_Springall.png",
      imgSize: "40px",
    },
    {
      rating: "⭐⭐⭐⭐⭐",
      text: "The platform's realistic simulations help candidates build confidence and gain insights into their performance. Great work to the team!",
      name: "Seddik Houimli",
      position: "Founder and CEO @SupplyzPro",
      img: "https://peppy-khapse-54c09c.netlify.app/Seddik_Houiml.png",
      imgSize: "40px",
    },
    {
      rating: "⭐⭐⭐⭐⭐",
      text: "Buddy is transformative! Mock interviews and constructive feedback make career preparation feel genuine and less intimidating.",
      name: "Khawla Gharbi",
      position: "Business Student at Tunis Business School",
      img: "https://peppy-khapse-54c09c.netlify.app/Khawla_Gharbi.png",
      imgSize: "40px",
    },
    {
      rating: "⭐⭐⭐⭐⭐",
      text: "I love how intuitive, responsive, and efficient Coffee Call is!",
      name: "Shameen Imran",
      position: "Student at King’s College London",
      img: "https://peppy-khapse-54c09c.netlify.app/Shameen_Imran.png",
      imgSize: "40px",
    },
    {
      rating: "⭐⭐⭐⭐⭐",
      text: "The AI Interviewer Buddy is unreal! It tailors questions based on my CV, making preparation much easier. Highly recommend for interview skills improvement.",
      name: "Vishal Kumar",
      position: "Undergraduate MSci at KCL",
      img: "https://peppy-khapse-54c09c.netlify.app/Vishal_Kumar.png",
      imgSize: "40px",
    },
    {
      rating: "⭐⭐⭐⭐⭐",
      text: "Some amazing help for chatting and improving. The report assessment is really a game changer.",
      name: "Killian Lesueur",
      position: "Student in Innovation Management & Entrepreneurship at University of Paris I",
      img: "https://peppy-khapse-54c09c.netlify.app/Killian_Lesueur.png",
      imgSize: "40px",
    },
  ];

  const duplicateReviews = [...reviews, ...reviews]; // Duplicate reviews for infinite scrolling

  const startInfiniteScroll = (ref, direction) => {
    let speed = direction === "left" ? -0.5 : 0.5;
    const scroll = () => {
      if (ref.current) {
        ref.current.scrollLeft += speed;
        if (ref.current.scrollLeft >= ref.current.scrollWidth / 2) {
          ref.current.scrollLeft = 0;
        }
        if (ref.current.scrollLeft <= 0) {
          ref.current.scrollLeft = ref.current.scrollWidth / 2;
        }
      }
    };
    return setInterval(scroll, 16); // Approximately 60 frames per second
  };

  useEffect(() => {
    const interval1 = startInfiniteScroll(row1Ref, "left");
    const interval2 = startInfiniteScroll(row2Ref, "right");

    return () => {
      clearInterval(interval1);
      clearInterval(interval2);
    };
  }, []);

  const TestimonialCard = ({ rating, text, name, position, img, imgSize }) => (
    <div className="flex-shrink-0 w-80 p-6  bg-white bg-opacity-5 backdrop-blur-md border border-gray-700  rounded-xl shadow-md">
      <div className="flex items-center space-x-4 mb-4">
        <img
          src={img}
          alt={name}
          className="rounded-full"
          style={{ width: imgSize, height: imgSize }}
        />
        <div>
          <div className="text-gray-200 text-md font-semibold">{name}</div>
          <div className="text-gray-300 text-sm">{position}</div>
        </div>
      </div>
      <div className="text-yellow-300 text-lg mb-2">{rating}</div>
      <p className="text-gray-300 text-sm">"{text}"</p>
    </div>
  );

  return (
    <div className="text-gray-900 py-12">
  <div className="container mx-auto px-4 !max-w-screen-2xl">
      <div className="p-10 flex flex-col justify-center items-center max-sm:p-0 max-sm:mb-8 max-sm:gap-3">
      <motion.h1
          className="landing-page-title-2 text-center-custom text-4xl text-slate-300 font-bold max-sm:text-2xl max-sm:text-center"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.5 }}
        >
          Reviews from our growing community
        </motion.h1>
        <motion.p
          className="custom-small-title text-slate-300 max-sm:text-sm"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.3 }}
        >
          2123 people have said how good buddy
        </motion.p>
        </div>
        <div className="space-y-8">
          <div ref={row1Ref} className="flex overflow-x-hidden space-x-4">
            {duplicateReviews.map((item, index) => (
              <TestimonialCard key={`row1-${index}`} {...item} />
            ))}
          </div>
          <div ref={row2Ref} className="flex overflow-x-hidden space-x-4">
            {duplicateReviews.map((item, index) => (
              <TestimonialCard key={`row2-${index}`} {...item} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestimonialCarousel;
