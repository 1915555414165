import React from "react";
import { Helmet } from "react-helmet"; // For meta tags
import { FaFacebookF, FaTwitter, FaLinkedinIn } from "react-icons/fa";
import personailty_roaster from "../../../assets/personality_roaster2.png";
import { useNavigate } from "react-router-dom";

const PersonalityRoaster = ({ data }) => {
    const navigate = useNavigate();
    const { roast } = data;
    const url = "https://faangai.com/templates/personalityroaster";
    const title = "Personality Roaster | Think you can handle the truth? 😅 Personality Roaster Buddy serves it hot! 🔥 Powered by FAANG.";
    const description = "Get brutally honest and hilarious personality roasts based on your resume! Try it now for free. 🚀";

    const handleSocialShare = (platform) => {
        const url = "https://faangai.com/templates/personalityroaster";
        const summary = "Get brutally honest and hilarious personality roasts based on your resume! Try it now for free. 🚀";
        const hashtags = "#FAANG #SuperBuddyMatch #FAANGAI #Buddy #AI_Interviewer";

        const theText = `Think you can handle the truth? 😅 Personality Roaster Buddy serves it hot! 🔥 Powered by @FAANG. Try it for free! 🚀 #PersonalityRoasterBuddy #FAANGAI #BuddyRoast\n ${url}`;

        const twittertext = `Think you can handle the truth? 😅 Personality Roaster Buddy serves it hot! 🔥 Powered by @FAANG. Try it for free! 🚀 #PersonalityRoasterBuddy #FAANGAI #BuddyRoast`;

        const shareUrls = {
            // Facebook: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}&quote=${encodeURIComponent(theText)}`, // Use quote for Facebook title
            Facebook: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}&quote=${encodeURIComponent(summary)}&hashtag=${encodeURIComponent(hashtags)}&href=${url}`,
            Twitter: `https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}&text=${encodeURIComponent(twittertext)}`,
            LinkedIn: `https://www.linkedin.com/shareArticle?mini=true&text=${encodeURIComponent(theText)}`, // Keep LinkedIn text separate for control
        };
        window.open(shareUrls[platform], "_blank", "noopener,noreferrer");

    };

    const handleTryAgain = () => {
        navigate("/templates/personalityroaster");
    };

    return (
        <>
            {/* Add meta tags for SEO and social sharing */}
            <Helmet>
                <title>{title}</title>
                <meta property="og:title" content={title} />
                <meta property="og:description" content={description} />
                <meta property="og:image" content="https://www.faangai.com/assets/personaolity_roaster.png" />
                <meta property="og:url" content={url} />
                <meta property="og:type" content="website" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={title} />
                <meta name="twitter:description" content={description} />
                <meta name="twitter:image" content="https://www.faangai.com/assets/personaolity_roaster.png" />
            </Helmet>

            <div className="relative max-w-[1340px] h-auto mx-auto flex flex-col max-sm:p-[10px] text-white p-6">
                <div className="lg:w-[786px] my-[50px] mx-auto flex flex-col gap-[50px] items-center">
                    {/* Page Title */}
                    <h1 className="w-full relative text-3xl font-bold text-center bg-gradient-to-r from-[#E1E1E1] to-[#929292] bg-clip-text text-transparent">
                        Personality Roast
                    </h1>

                    {/* Image */}
                    <img className="h-[150px] mt-1" src={personailty_roaster} alt="Buddy" />

                    {/* Roast Card */}
                    <div className="p-6 md:p-8 bg-gradient-to-b from-[#6c68e42e] to-[#1f1d36] rounded-3xl shadow-lg hover:shadow-2xl w-full relative transition-all duration-300">
                        {/* Header Section */}
                        <div className="flex flex-col">
                            {/* Title with Fire Icon */}
                            <div className="flex items-center justify-between">
                                <div className="flex items-center gap-2">
                                    <span className="text-red-500 text-2xl">
                                        🔥
                                    </span>
                                    <h2 className="text-xl font-bold text-gray-200">Think you can handle the truth?</h2>
                                </div>

                                {/* Social Sharing Buttons */}
                                <div className="flex gap-4">
                                    {/* Facebook */}
                                    <button
                                        onClick={() => handleSocialShare("Facebook")}
                                        className="p-3 bg-blue-600 rounded-full hover:bg-blue-800 transition-all duration-300"
                                        aria-label="Share on Facebook"
                                    >
                                        <FaFacebookF className="text-white text-lg" />
                                    </button>
                                    {/* Twitter */}
                                    <button
                                        onClick={() => handleSocialShare("Twitter")}
                                        className="p-3 bg-blue-400 rounded-full hover:bg-blue-600 transition-all duration-300"
                                        aria-label="Share on Twitter"
                                    >
                                        <FaTwitter className="text-white text-lg" />
                                    </button>
                                    {/* LinkedIn */}
                                    <button
                                        onClick={() => handleSocialShare("LinkedIn")}
                                        className="p-3 bg-blue-700 rounded-full hover:bg-blue-900 transition-all duration-300"
                                        aria-label="Share on LinkedIn"
                                    >
                                        <FaLinkedinIn className="text-white text-lg" />
                                    </button>
                                </div>
                            </div>

                            {/* Separator Line */}
                            <div className="h-[1px] bg-gray-600 my-4"></div>
                        </div>

                        {/* Body Section */}
                        <p className="text-lg leading-relaxed text-gray-300">{roast}</p>

                    </div>
                    <button
                        onClick={handleTryAgain}
                        className="mt-1 py-3 px-6 bg-gradient-to-r from-purple-500 to-indigo-600 text-white text-lg font-semibold rounded-full hover:from-purple-600 hover:to-indigo-700 transition-all duration-300"
                    >
                        Try Again
                    </button>
                </div>
            </div>

        </>
    );
};

export default PersonalityRoaster;
