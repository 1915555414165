import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { FaFileUpload, FaCheckCircle, FaSpinner, FaClipboard } from "react-icons/fa";
import * as Icons from "react-icons/fa";
import { motion } from "framer-motion";
import { Helmet } from "react-helmet";
import { careerJobApplication } from "../../../ApiEnpoint/ApiEndpoints";
import Astronaut from "../../../assets/mock_interview2.png"

const ApplyForJob = () => {
    const { jobID } = useParams();
    const [role, setRole] = useState(null);
    const [copySuccess, setCopySuccess] = useState('');
    const [file, setFile] = useState(null);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const navigate = useNavigate();


    const job = role || {
        title: "Python Backend Developer",
        description: `
            Join our team to design and implement backend solutions, collaborate with frontend developers, and ensure high-performance systems.

            **Responsibilities:**
            - Develop scalable backend systems.
            - Collaborate with frontend developers.
            - Write clean, efficient, and reusable code.
            - Debug and resolve issues effectively.
            - Develop scalable backend systems.
            - Collaborate with frontend developers.
            - Write clean, efficient, and reusable code.
            - Debug and resolve issues effectively.

            **Requirements:**
            - Proficiency in Python (Django/Flask).
            - Experience with REST APIs and databases.
            - Strong problem-solving and debugging skills.
            - Proficiency in Python (Django/Flask).
            - Experience with REST APIs and databases.
            - Strong problem-solving and debugging skills.
        `,
        fullDescription:
            "Join our team to design and implement backend solutions, collaborate with frontend developers, and ensure high-performance systems.",
    };


    useEffect(() => {
        const storedRole = sessionStorage.getItem("selectedRole");
        if (storedRole) {
            setRole(JSON.parse(storedRole));
        } else {
            console.error("Role data is missing");
        }
    }, []);

    const renderIcon = (iconName) => {
        const IconComponent = Icons[iconName];
        return IconComponent ? <IconComponent className="text-white text-xl" /> : null;
    };

    const handleCopy = () => {
        const jobDescription = role?.fullDescription.map((section) => {
            return section.content
                .map((item) => (typeof item === 'string' ? item : item.items.join('\n')))
                .join('\n\n');
        }).join('\n\n');

        navigator.clipboard.writeText(jobDescription).then(() => {
            setCopySuccess('Copied!');
            setTimeout(() => setCopySuccess(''), 1000);
        }).catch(() => {
            setCopySuccess('Failed to copy');
        });
    };

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile && selectedFile.type === "application/pdf") {
            setFile(selectedFile);
        } else {
            alert("Only PDF files are allowed.");
        }
    };

    const handleRemoveFile = () => {
        setFile(null);
    };

    const handleFileDrop = (event) => {
        event.preventDefault();
        if (event.dataTransfer.files && event.dataTransfer.files[0]) {
            setFile(event.dataTransfer.files[0]);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (file) {
            const formData = {
                job_title: role?.title || "",
                job_id: jobID,
                name,
                email,
            };

            // Retrieve the existing jobs from localStorage
            const existingJobs = JSON.parse(localStorage.getItem('appliedJobs')) || [];

            // Check if the job is already applied to avoid duplicates
            const isJobAlreadyApplied = existingJobs.some(
                (job) => job.job_id === formData.job_id
            );

            if (!isJobAlreadyApplied) {
                // Add the new job to the array
                existingJobs.push({
                    job_title: formData.job_title,
                    job_id: formData.job_id,
                });

                // Update localStorage with the new array
                localStorage.setItem('appliedJobs', JSON.stringify(existingJobs));
            }

            setLoading(true);
            setError("");

            try {
                const response = await careerJobApplication(formData, file);
                console.log("API Response:", response);

                if (response.message === "Your application was already submitted.") {
                    setError(response.message);
                } else {
                    navigate(`/career-page/${role?.id}/success`, { state: role });
                }
            } catch (error) {
                console.error("Error submitting application:", error);
                setError("There was an error submitting your application. Please try again.");
            } finally {
                setLoading(false);
            }
        } else {
            setError("Please upload your CV before submitting.");
        }
    };

    return (
        <>
            <Helmet>
                <title>{job.title} | Careers at FAANG - Join Our Mission</title>
                <meta name="description" content={job.description.slice(0, 160)} />
                <meta
                    name="keywords"
                    content="Job Application, Backend Developer, Python, Django, Flask, Careers, Join Team"
                />
                <meta name="author" content="FaangAI" />
                <meta property="og:title" content={`Apply for ${job.title}`} />
                <meta property="og:description" content={job.description.slice(0, 160)} />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={`http://faangai.com/career-page/${jobID}`} />
                <meta
                    property="og:image"
                    content="https://www.faangai.com/assets/faang_text_logo.png"
                />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={`Apply for ${job.title}`} />
                <meta name="twitter:description" content={job.description.slice(0, 160)} />
                <meta
                    name="twitter:image"
                    content="https://www.faangai.com/assets/faang_text_logo.png"
                />
            </Helmet>


            <div className="min-h-screen text-gray-200 flex flex-col justify-center items-center py-8 px-4 mb-20">
                <header className="text-center mb-12">
                    <motion.h1
                        className="text-3xl sm:text-4xl font-extrabold text-white leading-tight"
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.6 }}
                    >
                        Apply for our <span className="text-indigo-400">{job.title}</span> Position 🚀
                    </motion.h1>
                    <motion.p
                        className="text-gray-300 mt-4 text-sm sm:text-md"
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.8, delay: 0.2 }}
                    >
                        Explore exciting opportunities and join our mission to revolutionize career prep.
                    </motion.p>
                </header>

                <div className="border border-gray-700 rounded-[30px] flex flex-col lg:flex-row items-stretch bg-gradient-to-br from-[#7A77CC1A] to-[#7a77cc3b] text-gray-100">
                    {/* Job Description Section */}
                    <div className="w-full lg:w-[60%] p-4 sm:p-8">
                        <header className="px-4 sm:px-6 py-4 border-b border-gray-700">
                            <div className="flex items-center mb-3">
                                <div className="flex items-center justify-center w-8 sm:w-10 h-8 sm:h-10 bg-gradient-to-br from-indigo-500 to-purple-500 rounded-full shadow-md mr-3">
                                    {renderIcon(role?.icon)}
                                </div>
                                <h1 className="text-2xl sm:text-3xl font-extrabold text-gray-100">
                                    <span className="text-indigo-400">{role?.title}</span>
                                </h1>
                            </div>
                        </header>
                        <div className="flex justify-end items-center">
                            <div className="flex justify-center items-center">
                                <button
                                    onClick={handleCopy}
                                    className={`mt-4 text-sm px-6 py-3 rounded-lg flex items-center justify-center shadow-lg transition-all duration-300 ease-in-out 
                ${copySuccess
                                            ? "bg-gradient-to-r from-indigo-200 to-indigo-300 text-white hover:from-indigo-300 hover:to-indigo-400"
                                            : "bg-gradient-to-r from-indigo-500 to-indigo-600 text-white hover:from-indigo-600 hover:to-indigo-700"
                                        }
            `}
                                    style={{
                                        transform: copySuccess ? "translateY(2px)" : "translateY(0)",
                                        boxShadow: copySuccess
                                            ? "0 4px 6px rgba(0, 0, 0, 0.1)"
                                            : "0 6px 10px rgba(0, 0, 0, 0.2)",
                                    }}
                                >
                                    {copySuccess ? (
                                        <Icons.FaCheck className={`text-lg ${copySuccess ? "text-indigo-700" : "text-white"}`} />
                                    ) : (
                                        <FaClipboard className="text-lg" />
                                    )}
                                    <span
                                        className={`ml-2 ${copySuccess ? "text-indigo-700" : "text-white"}`}
                                    >
                                        {copySuccess ? "Copied!" : "Copy Job Description"}
                                    </span>
                                </button>
                            </div>
                        </div>
                        <div className="p-6 max-w-3xl mx-auto rounded-lg mt-4 overflow-scroll max-h-[700px] bg-[#3f386f] p-[29px]">
                            {role?.fullDescription.map((section, index) => (
                                <div key={index} className="mb-8">
                                    <h2 className="text-xl font-semibold text-gray-200 mb-4">
                                        {section.section}
                                    </h2>
                                    {Array.isArray(section.content)
                                        ? section.content.map((item, idx) => {
                                            if (typeof item === 'string') {
                                                return (
                                                    <p key={idx} className="text-sm text-gray-300 mb-4">
                                                        {item}
                                                    </p>
                                                );
                                            } else if (item.title) {
                                                return (
                                                    <div key={idx}>
                                                        <h3 className="text-md font-semibold text-gray-200 mt-4">
                                                            {item.title}
                                                        </h3>
                                                        <ul className="list-disc pl-5">
                                                            {item.items.map((listItem, listIdx) => (
                                                                <li key={listIdx} className="text-sm text-gray-300">
                                                                    {listItem}
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                );
                                            }
                                        })
                                        : null}
                                </div>
                            ))}
                        </div>


                    </div>

                    {/* Application Form Section */}
                    {/* <div className="rounded-tr-[30px] rounded-br-[30px] w-full lg:w-[40%] p-4 sm:p-8 bg-[#2e2857] flex flex-col items-center justify-center"> */}

                    <div className="bg-[#42417529] rounded-tr-[30px] rounded-br-[30px] w-full lg:w-[40%] p-4 sm:p-8">
                        <header className="px-4 sm:px-6 py-4 border-b border-gray-700 mb-14">
                            <div className="flex items-center mb-4">


                                <motion.h1
                                    className="text-2xl sm:text-3xl font-extrabold text-gray-200"
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ duration: 0.6 }}
                                >
                                    Enter your Details
                                </motion.h1>
                            </div>

                        </header>
                        <div className="w-full h-[75%] flex flex-col items-center justify-center">

                            <img src={Astronaut} className="h-[150px] opacity-90 mb-5" />

                            <form onSubmit={handleSubmit} className="space-y-6 w-full">
                                {error && (
                                    <div className="p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg">
                                        {error}
                                    </div>
                                )}
                                <div>
                                    <label htmlFor="name" className="block text-sm font-medium text-gray-300">
                                        Full Name
                                    </label>
                                    <input
                                        type="text"
                                        id="name"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        className="mt-2 p-2 w-full rounded-lg border border-gray-700 bg-[#8888882b] text-white"
                                        required
                                    />
                                </div>

                                <div>
                                    <label htmlFor="email" className="block text-sm font-medium text-gray-300">
                                        Email Address
                                    </label>
                                    <input
                                        type="email"
                                        id="email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        className="mt-2 p-2 w-full rounded-lg border border-gray-700 bg-[#8888882b] text-white"
                                        required
                                    />
                                </div>



                                <div className="flex gap-3 flex-col">
                                    <label htmlFor="cv" className="block text-sm font-medium text-gray-300">
                                        Upload Your CV
                                    </label>
                                    <div
                                        className={`flex flex-col justify-center items-center cursor-pointer space-y-4 bg-[#342f57]  w-full h-40 p-6 rounded-[10px] border border-gray-300 border-dashed ${file ? "space-y-2" : ""
                                            }`}
                                        onClick={() => {
                                            if (!file) {
                                                const fileInput = document.getElementById("file-input");
                                                if (fileInput) {
                                                    fileInput.click();
                                                }
                                            }
                                        }}
                                    >
                                        {!file ? (
                                            <>
                                                <iframe
                                                    style={{
                                                        width: "92px",
                                                        height: "92px",
                                                        marginBottom: "-30px",
                                                        marginTop: "-30px",
                                                        pointerEvents: "none",
                                                    }}
                                                    src="https://lottie.host/embed/70ab5fa4-fc03-4c26-872d-162f47f64cf3/cirfjdmhLc.json"
                                                ></iframe>
                                                <span
                                                    style={{ fontSize: "12px" }}
                                                    className="font-semibold"
                                                >
                                                    Drag your PDF file(s) or{" "}
                                                    <span className="text-indigo-600 ml-1 font-semibold">
                                                        browse
                                                    </span>
                                                </span>
                                                <span
                                                    style={{
                                                        fontSize: "10px",
                                                        marginTop: "2px",
                                                        color: "#7a7a7a",
                                                    }}
                                                    className="dark:text-gray-400"
                                                >
                                                    Max 10 MB files are allowed
                                                </span>
                                                <input
                                                    type="file"
                                                    id="file-input"
                                                    onChange={handleFileChange}
                                                    className="hidden"
                                                    accept=".pdf" // Only allow PDF files
                                                />
                                            </>
                                        ) : (
                                            <div className="w-full flex flex-col items-center">
                                                <div className="text-green-400 flex items-center mb-2">
                                                    {file?.type === "application/pdf" && <Icons.FaFilePdf className="text-6xl mr-2 text-[#a4adff]" />}

                                                </div>
                                                <span className="truncate">{file.name}</span>
                                                <div className="flex space-x-4">
                                                    <button
                                                        onClick={() => document.getElementById("file-input").click()}
                                                        className="text-indigo-600 font-semibold text-sm hover:underline"
                                                    >
                                                        Replace
                                                    </button>
                                                    <button
                                                        onClick={handleRemoveFile}
                                                        className="text-red-500 font-semibold text-sm hover:underline"
                                                    >
                                                        Remove
                                                    </button>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <span
                                        style={{ fontSize: "10px" }}
                                        className="dark:text-gray-400"
                                    >
                                        Only .pdf files are supported
                                    </span>
                                </div>


                                <div className="flex justify-center">
                                    <button
                                        type="submit"
                                        className="px-6 py-2 sm:px-8 sm:py-2 bg-indigo-500 text-white font-semibold text-sm sm:text-base rounded-full shadow-lg hover:bg-indigo-400 hover:shadow-indigo-500/50 focus:ring-4 focus:ring-indigo-300 focus:outline-none transition-all duration-300 flex items-center justify-center"
                                        disabled={loading}
                                    >
                                        {loading ? (
                                            <FaSpinner className="animate-spin mr-2 mt-1 ml-1 mb-1" />
                                        ) : (
                                            "Submit Application"
                                        )}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default ApplyForJob;
