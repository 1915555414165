// apiService.js
import axios from 'axios';

const BASE_URL = 'https://dashboard.faangai.com/api'

export const TemplateFunction = async (file, templatetype, jobDescription = '') => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('templatetype', templatetype);

    try {
        const response = await axios.post(`${BASE_URL}/process-template`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response.data;
    } catch (error) {
        console.error("Error uploading PDF:", error);
        throw error;
    }
};

export const getAllRoles = async () => {
    try {
        const response = await axios.get(`${BASE_URL}/roles`);
        return response.data;
    } catch (error) {
        console.error("Error fetching roles:", error);
        throw error;
    }
};

export const incrementLikes = async (roleId, pk) => {
    try {
        const response = await axios.post(`${BASE_URL}/roles/${roleId}/like`, { pk });
        return response.data;
    } catch (error) {
        console.error(`Error liking role ${roleId}:`, error);
        throw error;
    }
};

export const careerJobApplication = async (formData, cvFile) => {
    try {
      // Create FormData to handle the file upload
      const data = new FormData();
      data.append('job_title', formData.job_title);
      data.append('job_id', formData.job_id);
      data.append('name', formData.name);
      data.append('email', formData.email);
      data.append('cv', cvFile); // Add the CV file
  
      // Make the POST request
      const response = await axios.post(`${BASE_URL}/career_job_application`, data);
  
      return response.data;
    } catch (error) {
      console.error('Error calling careerJobApplication API:', error.response || error);
      throw error;
    }
  };

