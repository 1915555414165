import { useState, useRef, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import SVGUpload from "../../assets/SVG/SVGUpload";
import { FaFilePdf, FaTrashAlt } from "react-icons/fa";
import SVGEarth from "../../assets/SVG/SVGEarth";
import { TemplateFunction } from "../../ApiEnpoint/ApiEndpoints";
import personailty_roaster from "../../assets/personality_roaster2.png";
import ErrorPage from "../ErrorPage/ErrorPage";
import BuddyRocket from "../../assets/super_buddy.png";
import UploadCloud from "../../assets/lottie/Animation - 1732057412311.json";
import LoadingAnimation from "../../assets/lottie/Animation - 1732141770406.json"
import { FaUpload, FaCheckCircle, FaShareAlt } from "react-icons/fa";
import Lottie from "lottie-react";
import { Helmet } from "react-helmet"; // Import Helmet

export default function EachTemplate() {
    const fileRef = useRef();
    const { templateId } = useParams();
    const [uploadedFile, setUploadedFile] = useState(null);
    const [jobDescription, setJobDescription] = useState("");
    const [response, setResponse] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    console.log("templateId", templateId)

    // Scroll to top when loading starts
    useEffect(() => {
        if (loading) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }, [loading]);

    const handleInputClick = () => {
        if (fileRef.current) {
            fileRef.current.click();
        }
    };

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        if (file && file.type === "application/pdf") {
            setUploadedFile(file);
            setError(null);
        } else {
            setError("Only PDF files are supported.");
        }
    };

    const handleRunButton = async () => {
        if (!uploadedFile) {
            setError("Please upload a PDF file.");
            return;
        }

        setLoading(true);
        setError(null);
        try {
            const data = await TemplateFunction(uploadedFile, templateId, jobDescription);
            console.log("data", data);
            setResponse(data);
            const templateType = data.PK;
            console.log("navigated", `/templates/${templateId}/${data.ID}`);
            navigate(`/templates/${templateId}/${data.id}`, {
                state: { templateType: templateType, response: data },
            });
        } catch (err) {
            console.error("There was an error uploading the file:", err);
            setError("There was an error processing your request. Please try again.");
        } finally {
            setLoading(false);
        }
    };

    const handleRemoveFile = () => {
        setUploadedFile(null);
        setError(null);
    };

    const renderTemplateContent = () => {
        if (templateId === "superbuddy") {
            return (
                <>
                    <h4 className="text-center text-4xl font-extrabold bg-gradient-to-r from-gray-200 to-gray-400 bg-clip-text text-transparent">
                        Hey there, sidekick! I’m SuperBuddy!
                    </h4>
                    <p className="text-center text-sm font-thin text-gray-400 -mt-1">
                        I’m SuperBuddy, your guide to uncover your inner hero! Just upload your resume, and I’ll
                        match you with your superhero alter ego! Ready to suit up and unleash your superpowers?
                        🚀 Let’s go!
                    </p>
                    <img className="h-[170px] mt-2 -mb-1" src={BuddyRocket} alt="Buddy" />
                </>
            );
        }

        if (templateId === "personalityroaster") {
            return (
                <>
                    <h1 className="text-center text-4xl font-extrabold bg-gradient-to-r from-gray-200 to-gray-400 bg-clip-text text-transparent">
                        Hey, hotshot! I’m Roaster Buddy
                    </h1>
                    <p className="text-center text-sm font-thin text-gray-400 -mt-1">
                        I’m Roaster Buddy, your sarcastic sidekick ready to roast your CV like
                        never before. Drop your link, and I’ll give you the sharpest, funniest feedback you’ve
                        ever seen. Think you can handle my heat? 🔥 Let’s go!
                    </p>
                    <img className="h-[150px] mt-4" src={personailty_roaster} alt="Buddy" />
                </>
            );
        }

        return null;
    };

    const issuperbuddy = templateId === "superbuddy";
    const isPersonalityRoaster = templateId === "personalityroaster";

    // Meta tags variables
    const title = issuperbuddy
        ? "Super Buddy | Discover Your Superhero Alter Ego with SuperBuddy!"
        : isPersonalityRoaster
            ? "Roaster Buddy | Get Roasted by Roaster Buddy!"
            : "Buddy App";
    const description = issuperbuddy
        ? "Upload your resume and uncover your inner superhero with SuperBuddy!"
        : isPersonalityRoaster
            ? "Let Roaster Buddy give you the funniest feedback on your CV!"
            : "Welcome to Buddy App";
    const image = issuperbuddy
        ? "https://www.faangai.com/assets/super_buddy.png"
        : isPersonalityRoaster
            ? "https://www.faangai.com/assets/personaolity_roaster.png"
            : "https://faangai.com/assets/faang_logo_sqaure.png";
    const url = window.location.href;

    // Check if the templateId is recognized
    if (!issuperbuddy && !isPersonalityRoaster) {
        return <ErrorPage />;
    }

    return (
        <div className="w-full min-h-screen py-10 text-white">
            {/* Helmet for meta tags */}
            <Helmet>
                <title>{title}</title>
                <meta property="og:title" content={title} />
                <meta property="og:description" content={description} />
                <meta
                    property="og:image"
                    content={image}
                />
                <meta property="og:url" content={url} />
                <meta property="og:type" content="website" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={title} />
                <meta name="twitter:description" content={description} />
                <meta
                    name="twitter:image"
                    content={image}
                />
            </Helmet>

            {/* Modal for Loading */}
            {loading && (
               <div
               className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 backdrop-blur-sm"
               role="dialog"
               aria-modal="true"
               aria-labelledby="modal-title"
               aria-describedby="modal-description"
           >
               <div className="relative bg-gradient-to-br from-[#1C1C2E] via-[#2A2A40] to-[#1F1F33] p-10 rounded-3xl shadow-xl text-center w-full max-w-md transform transition-all duration-300 ease-out scale-100 hover:scale-105">
                  
                  
                   {/* Modal Content */}
                   <h2
                       id="modal-title"
                       className="text-4xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-purple-400 via-pink-500 to-red-500 mb-5 p-2"
                   >
                       Hang Tight!
                   </h2>
                   <p
                       id="modal-description"
                       className="text-gray-300 text-base mb-3 leading-relaxed"
                   >
                       We’re brewing something amazing for you. Just a moment!
                   </p>
           
                   {/* Lottie Animation */}
                   <div className="flex justify-center">
                       <Lottie
                           animationData={LoadingAnimation}
                           loop={true}
                           style={{
                               width: "140px",
                               height: "140px",
                           }}
                       />
                   </div>
               </div>
           </div>
           
            
            )}

            <div
                style={{
                    background:
                        "radial-gradient(112.32% 83.8% at 50.41% 50%, rgba(0, 0, 0, 0) 0%, rgba(152, 195, 189, 0.1) 100%)",
                }}
                className="w-11/12 lg:w-[1000px] mx-auto rounded-3xl p-8 lg:p-14 shadow-2xl relative"
            >
                <div className="flex flex-col items-center gap-8">{renderTemplateContent()}</div>

                <div className="mt-12 space-y-12 px-6 lg:px-12">
                    {issuperbuddy ? (
                        <>
                            <h2 className="text-center text-2xl font-semibold tracking-tight  bg-gradient-to-r from-gray-200 to-gray-400 bg-clip-text text-transparent">
                                How the Super Buddy Works
                            </h2>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                                <div className="flex flex-col bg-gradient-to-br from-[#7A77CC1A] to-[#7a77cc3b] rounded-xl p-8 shadow-md hover:shadow-lg border border-[#3b3b3b] transition-transform transform hover:-translate-y-1">
                                    <div className="flex items-center space-x-4">
                                        <div className="bg-[#5b57c4] p-3 rounded-full text-white text-xl">
                                            <FaUpload />
                                        </div>
                                        <h3 className="text-xl font-semibold text-gray-200">Step 1</h3>
                                    </div>
                                    <p className="mt-4 text-gray-300">
                                        Upload your resume in PDF format to reveal your superhero alter ego.
                                    </p>
                                </div>
                                <div className="flex flex-col bg-gradient-to-br from-[#7A77CC1A] to-[#7a77cc3b] rounded-xl p-8 shadow-md hover:shadow-lg border border-[#3b3b3b] transition-transform transform hover:-translate-y-1">
                                    <div className="flex items-center space-x-4">
                                        <div className="bg-[#5b57c4] p-3 rounded-full text-white text-xl">
                                            <FaCheckCircle />
                                        </div>
                                        <h3 className="text-xl font-semibold text-gray-200">Step 2</h3>
                                    </div>
                                    <p className="mt-4 text-gray-300">
                                        Get a superhero name, a meme, and a backstory based on your resume!
                                    </p>
                                </div>
                            </div>
                        </>
                    ) : isPersonalityRoaster ? (
                        <>
                            <h2 className="bg-gradient-to-r from-gray-200 to-gray-400 bg-clip-text text-transparent text-center text-2xl font-semibold tracking-tight">
                                How the Personality Roaster Buddy Works
                            </h2>
                            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                                <div className="flex flex-col bg-gradient-to-br from-[#7A77CC1A] to-[#7a77cc3b] rounded-xl p-8 shadow-md hover:shadow-lg border border-[#3b3b3b] transition-transform transform hover:-translate-y-1">
                                    <div className="flex items-center space-x-4">
                                        <div className="bg-[#5b57c4] p-3 rounded-full text-white text-xl">
                                            <FaUpload />
                                        </div>
                                        <h3 className="text-xl font-semibold text-gray-200">Step 1</h3>
                                    </div>
                                    <p className="mt-4 text-gray-300">
                                        Upload your resume in PDF format to get started.
                                    </p>
                                </div>
                                <div className="flex flex-col bg-gradient-to-br from-[#7A77CC1A] to-[#7a77cc3b] rounded-xl p-8 shadow-md hover:shadow-lg border border-[#3b3b3b] transition-transform transform hover:-translate-y-1">
                                    <div className="flex items-center space-x-4">
                                        <div className="bg-[#5b57c4] p-3 rounded-full text-white text-xl">
                                            <FaCheckCircle />
                                        </div>
                                        <h3 className="text-xl font-semibold text-gray-200">Step 2</h3>
                                    </div>
                                    <p className="mt-4 text-gray-300">
                                        Our system will analyze your resume and generate a hilarious roast!
                                    </p>
                                </div>
                                <div className="flex flex-col bg-gradient-to-br from-[#7A77CC1A] to-[#7a77cc3b] rounded-xl p-8 shadow-md hover:shadow-lg border border-[#3b3b3b] transition-transform transform hover:-translate-y-1">
                                    <div className="flex items-center space-x-4">
                                        <div className="bg-[#5b57c4] p-3 rounded-full text-white text-xl">
                                            <FaShareAlt />
                                        </div>
                                        <h3 className="text-xl font-semibold text-gray-200">Step 3</h3>
                                    </div>
                                    <p className="mt-4 text-gray-300">
                                        Share your roast on LinkedIn or Twitter for some fun!
                                    </p>
                                </div>
                            </div>
                        </>
                    ) : null}
                </div>

                <div className="mt-12 text-center">
                    <p className="text-gray-400">
                        Upload your resume, and{" "}
                        {issuperbuddy
                            ? "get your superhero identity!"
                            : "get roasted to perfection!"}{" "}
                        🔥
                    </p>
                    <div
                        className="mt-6  bg-gradient-to-br from-[#7A77CC1A] to-[#7a77cc3b] rounded-2xl cursor-pointer h-48 flex flex-col items-center justify-center border border-dashed border-indigo-500 hover:bg-gray-700 transition duration-300"
                        onClick={handleInputClick}
                    >
                        {!uploadedFile ? (
                            <>
                                <Lottie
                                    animationData={UploadCloud}
                                    loop={true}
                                    style={{
                                        width: "120px",
                                        height: "120px",
                                        transition: "opacity 1s ease-in-out",
                                        border: "none",
                                    }}
                                />

                                <p className="text-gray-400 mt-4">Click to upload your resume</p>
                                <input
                                    ref={fileRef}
                                    type="file"
                                    className="hidden"
                                    accept=".pdf"
                                    onChange={handleFileUpload}
                                />
                            </>
                        ) : (
                            <div className="flex flex-col items-center">
                                <FaFilePdf className="text-red-500 text-6xl" />
                                <p className="mt-4">{uploadedFile.name}</p>
                                <button
                                    className="mt-4 bg-red-600 hover:bg-red-700 text-white py-2 px-6 rounded-full"
                                    onClick={handleRemoveFile}
                                >
                                    Remove File
                                </button>
                            </div>
                        )}
                    </div>
                    <p className="text-gray-500 text-sm mt-4">Only support .pdf files</p>
                    {error && <p className="text-red-500 mt-4">{error}</p>}
                </div>

                <div className="flex justify-center mt-10">
                    <button
                        onClick={handleRunButton}
                        className={`bg-gradient-to-r from-indigo-500 to-purple-600 hover:from-indigo-600 hover:to-purple-700 text-white font-bold py-3 px-12 rounded-full shadow-lg transition-transform transform ${loading ? "opacity-50 cursor-not-allowed" : "hover:scale-105"
                            }`}
                        disabled={loading}
                    >
                        {loading ? "Processing..." : "Run"}
                    </button>
                </div>
            </div>
        </div>
    );
}
