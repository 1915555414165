import { useEffect } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import Confetti from "react-confetti";
import astronaut from "../../../assets/our_vision.png"; // Replace with the actual path to your astronaut image

const JobSuccess = () => {
    const { state } = useLocation();
    const { jobId } = useParams();
    const navigate = useNavigate();

    // useEffect(() => {
    //     const timer = setTimeout(() => {
    //         navigate("/dashboard");
    //     }, 15000); // Redirect after 15 seconds
    //     return () => clearTimeout(timer);
    // }, [navigate]);

    return (
        <div className="min-h-screen text-gray-200 flex flex-col justify-center items-center py-8 px-4 mb-20">
            <Confetti
                width={window.innerWidth}
                height={window.innerHeight}
                recycle={false}
                numberOfPieces={500}
            />
            <div
                className="max-w-6xl bg-white bg-opacity-5 backdrop-blur-md border border-gray-700 rounded-2xl shadow-lg hover:shadow-2xl transition-shadow duration-300 p-6 text-white"
                style={{
                    boxShadow:
                        "0 6px 16px rgba(0, 0, 0, 0.2), 0 3px 8px rgba(255, 255, 255, 0.1)",
                }}
            >
                <div className="text-center space-y-6 max-w-2xl">
                    <img src={astronaut} className="h-[160px] mx-auto" alt="Astronaut" />

                    <h1 className="text-2xl font-extrabold text-white">
                        Amazing! We now have your application.
                    </h1>

                    <p className="text-gray-200 text-lg">
                        To maximize your chances of rocking your interview, we are offering you{" "}
                        <span className="text-green-400 font-semibold">5 free coins</span> to practice for this job.
                        Just use the code <span className="text-indigo-400 font-semibold">"FaangInterview"</span> and you're good to go 🚀
                    </p>
                    <a
                        href="https://dashboard.faangai.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="inline-block mt-4 px-6 py-2 bg-indigo-500 text-white font-semibold text-base rounded-full shadow-lg hover:bg-indigo-400 hover:shadow-indigo-500/50 focus:ring-4 focus:ring-indigo-300 focus:outline-none transition-all duration-300"
                    >
                        Start Practicing Now
                    </a>
                </div>
            </div>
        </div>
    );
};

export default JobSuccess;
