import React from "react";
import { FaBullseye, FaHandsHelping } from "react-icons/fa";
import { useInView } from "react-intersection-observer";
import empowermentImg from "../../assets/empowerment.png";
import accessibilityImg from "../../assets/idea.webp";
import communityImg from "../../assets/community.png";
import innovationImg from "../../assets/online_interview.png";
import rihabPicture from "../../assets/rihab_picture.jpeg";
import badisPicture from "../../assets/badis_picture.jpg";
import curiosityImg from "../../assets/Curiosity.png";
import growthImg from "../../assets/Growth.png";
import inclusivityImg from "../../assets/Inclusivity.png";
import our_approach from "../../assets/our_approach.png";
import our_vision_1 from "../../assets/our_vision_1.png";



const Section = ({ children }) => {
    const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.15 });
    return (
        <div
            ref={ref}
            className={`transition-all duration-700 transform ${inView ? "opacity-100 translate-y-0" : "opacity-0 translate-y-8"
                }`}
        >
            {children}
        </div>
    );
};

const AboutUs = () => {
    return (
        <div className="w-full text-gray-300">
            {/* Header Section */}
            <Section>
                <div
                    className="relative py-20 text-center">
                    {/* Frosted Glass Effect */}
                    <div
                        className="absolute inset-0 bg-gradient-to-r from-[#6e6e6e1a] to-[#5744a333] backdrop-blur-md rounded-lg shadow-lg">
                    </div>
                    {/* Content */}
                    <div className="relative z-10">
                        <h1 className="text-4xl font-extrabold text-white mb-4">
                            About Us
                        </h1>
                        <p className="text-lg max-w-3xl mx-auto text-gray-200">
                            At FAANG, we’re not just rethinking interviews—we’re reinventing
                            them. Our mission is to empower candidates by turning AI into a
                            tool for skill-building, confidence, and self-presentation.
                        </p>
                    </div>
                </div>

            </Section>

            {/* Vision and Approach Section */}
            <Section>
                <div className="py-20  px-6 md:px-16">
                    <div className="grid md:grid-cols-2 gap-12 items-center">
                        <div className="space-y-4">
                            <FaBullseye className="text-indigo-500 text-5xl" />
                            <h2 className="text-3xl font-bold text-white">Our Vision</h2>
                            <p className="text-lg font-thin text-white">
                                We envision stress-free interviews as opportunities to shine.
                                FAANG’s AI creates an interview experience that’s engaging,
                                personalized, and empowering for all job seekers.
                            </p>
                        </div>
                        <div>
                            <img
                                src={our_vision_1}
                                alt="Vision"
                                className="rounded-lg h-[450px]"
                            />
                        </div>
                    </div>
                </div>
            </Section>

            <Section>
                <div className="py-14 px-6 md:px-16 bg-[#3e437a99]">
                    <div className="grid md:grid-cols-2 gap-12 items-center">
                        <div>
                            <img
                                src={our_approach}
                                alt="Approach"
                                className="rounded-lg h-[450px]"
                            />
                        </div>
                        <div className="space-y-4">
                            <FaHandsHelping className="text-indigo-500 text-5xl" />
                            <h2 className="text-3xl font-bold text-white">Our Approach</h2>
                            <p className="text-lg font-thin text-white">
                                At FAANG, we’re more than just an AI tool—we’re your personal
                                interview coach. Our platform adapts to your strengths, areas
                                for improvement, and specific job roles, helping you refine
                                skills and tailor responses with confidence.
                            </p>
                        </div>
                    </div>
                </div>
            </Section>

            {/* Our Values Section */}
            <Section>
                <div className="py-20 px-6 md:px-16">
                    <h2 className="text-3xl font-bold text-center text-white mb-10">
                        Our Values
                    </h2>
                    <div className="grid sm:grid-cols-2 md:grid-cols-4 gap-8">
                        {[
                            {
                                img: curiosityImg,
                                title: "Curiosity",
                                desc: "Inspiring a spirit of exploration and discovery.",
                            },
                            {
                                img: growthImg,
                                title: "Growth",
                                desc: "Fostering a mindset of continuous self-improvement.",
                            },
                            {
                                img: empowermentImg,
                                title: "Empowerment",
                                desc: "Enabling individuals to build confidence and reach their full potential.",
                            },
                            {
                                img: inclusivityImg,
                                title: "Inclusivity",
                                desc: "Creating a supportive and accessible environment for all users.",
                            },

                        ].map((value, index) => (
                            <div
                                key={index}
                                className="bg-gradient-to-br from-[#7A77CC1A] to-[#7a77cc3b] backdrop-blur-lg rounded-lg p-10 text-center shadow-lg hover:shadow-xl transition-shadow"
                            >
                                <img
                                    src={value.img}
                                    alt={value.title}
                                    className="w-16 h-16 mx-auto mb-4 rounded-full"
                                />
                                <h3 className="text-xl font-semibold text-white">
                                    {value.title}
                                </h3>
                                <p className="text-sm mt-2">{value.desc}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </Section>

            {/* Meet the Team Section */}
            <Section>
                <div className="py-14 px-6 md:px-10 bg-[#3e437a99]">
                    <section className="mb-16">
                        <h2 className="text-3xl font-bold text-white text-center mb-10">
                            Meet the Team
                        </h2>
                        <p className="text-gray-300 text-center max-w-xl mx-auto leading-relaxed mb-12">
                            We’re a diverse, innovative team on a mission to transform the interview experience.
                        </p>
                        <div className="flex flex-wrap justify-center gap-12">
                            {[
                                {
                                    img: rihabPicture,
                                    name: "Rihab Lajmi",
                                    role: "Co-founder & CEO",
                                },
                                {
                                    img: badisPicture,
                                    name: "Badis Zormati",
                                    role: "Co-founder & CTO",
                                },
                            ].map((member, index) => (
                                <div key={index} className="flex flex-col items-center">
                                    <div className="w-40 h-40 mb-4">
                                        <img
                                            src={member.img}
                                            alt={member.name}
                                            className="w-full h-full object-cover rounded-full border-4 border-indigo-600"
                                        />
                                    </div>
                                    <h3 className="text-xl font-semibold text-white mb-1">
                                        {member.name}
                                    </h3>
                                    <p className="text-gray-400">{member.role}</p>
                                </div>
                            ))}
                        </div>
                    </section>
                </div>
            </Section>

            {/* Call to Action Section */}
            <Section>
                <div className="py-20 text-center -mb-12">
                    <h2 className="text-3xl font-bold text-white mb-6">
                        Join the FAANG Community
                    </h2>
                    <p className="text-lg max-w-2xl mx-auto mb-8">
                        Ready to transform your interview experience? Join us today and
                        take the first step towards landing your dream job.
                    </p>
                    <a
                        href="https://discord.gg/yTEAEqXqJQ"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="bg-indigo-500 text-white px-8 py-3 rounded-full font-semibold text-lg hover:bg-indigo-400 transition-colors shadow-md"
                    >
                        Get Started
                    </a>
                </div>
            </Section>
        </div>
    );
};

export default AboutUs;
