import { FaArrowRight, FaThumbsUp } from "react-icons/fa";
import * as Icons from "react-icons/fa"; // Import all icons from react-icons/fa
import { motion } from "framer-motion"; // Import motion for animations
import { useEffect, useState } from "react";
import { getAllRoles, incrementLikes } from "../../ApiEnpoint/ApiEndpoints";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet"; // For meta tags

const CareersPage = () => {
  const [roles, setRoles] = useState([]);
  const [likes, setLikes] = useState({}); // Changed from array to object
  const [clicked, setClicked] = useState({}); // Changed from array to object
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true); // Loading state

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const rolesData = await getAllRoles();
        setRoles(rolesData);

        // Initialize likes and clicked state
        const initialLikes = {};
        const initialClicked = {};
        rolesData.forEach((role) => {
          initialLikes[role.id] = role.likes || 0;
          initialClicked[role.id] = localStorage.getItem(`liked_${role.id}`) === 'true';
        });
        setLikes(initialLikes);
        setClicked(initialClicked);
      } catch (error) {
        console.error('Error fetching roles:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchRoles();
  }, []);

  const handleLike = async (roleId, PK) => {
    // Prevent liking if already liked
    if (localStorage.getItem(`liked_${roleId}`) === 'true') {
      return;
    }

    // Optimistic UI update
    setLikes((prevLikes) => ({
      ...prevLikes,
      [roleId]: (prevLikes[roleId] || 0) + 1,
    }));
    setClicked((prevClicked) => ({
      ...prevClicked,
      [roleId]: true,
    }));

    // Save liked status in localStorage
    localStorage.setItem(`liked_${roleId}`, 'true');

    try {
      // API call to increment likes
      const updatedRole = await incrementLikes(roleId, PK);

      // Update likes with the actual API response
      setLikes((prevLikes) => ({
        ...prevLikes,
        [roleId]: updatedRole.likes,
      }));
    } catch (error) {
      console.error(`Error liking role ${roleId}:`, error);

      // Rollback optimistic update
      setLikes((prevLikes) => ({
        ...prevLikes,
        [roleId]: (prevLikes[roleId] || 0) - 1,
      }));
      setClicked((prevClicked) => ({
        ...prevClicked,
        [roleId]: false,
      }));

      // Remove liked status from localStorage in case of error
      localStorage.removeItem(`liked_${roleId}`);
    }
  };

  const handleApplyClick = (role) => {
    sessionStorage.setItem('selectedRole', JSON.stringify(role));
    const newTab = window.open(`/career-page/${role.id}`, '_blank');
    if (newTab) newTab.focus();
  };
  // Function to render the correct icon based on the icon name from the schema
  const renderIcon = (iconName) => {
    const IconComponent = Icons[iconName]; // Dynamically get the icon component
    return IconComponent ? <IconComponent className="text-white text-lg" /> : null;
  };

  return (
    <>
      <Helmet>
        <title>Careers at FAANG - Join Our Mission</title>
        <meta
          name="description"
          content="Explore exciting career opportunities at FAANG. Join us in revolutionizing career preparation with innovative solutions."
        />
        <meta
          name="keywords"
          content="FAANG careers, job opportunities, work at FAANG, careers in tech"
        />

        {/* Open Graph Meta Tags */}
        <meta property="og:title" content="Careers at FAANG - Join Our Mission" />
        <meta
          property="og:description"
          content="Explore exciting career opportunities at FAANG. Join us in revolutionizing career preparation with innovative solutions."
        />
        <meta property="og:url" content="https://www.faangai.com/career-page" />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://www.faangai.com/assets/faang_text_logo.png"
        />
        <meta
          property="og:image:alt"
          content="Explore career opportunities at FAANG and join a mission-driven team."
        />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />

        {/* Twitter Card Tags for Twitter Sharing */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Careers at FAANG - Join Our Mission" />
        <meta
          name="twitter:description"
          content="Explore exciting career opportunities at FAANG. Join us in revolutionizing career preparation with innovative solutions."
        />
        <meta
          name="twitter:image"
          content="https://www.faangai.com/assets/faang_text_logo.png"
        />
        <meta
          name="twitter:image:alt"
          content="Explore career opportunities at FAANG and join a mission-driven team."
        />
        <link rel="canonical" href="https://www.faangai.com/career-page" />
      </Helmet>

      <div className="min-h-screen px-6 py-12">
        {/* Header Section */}
        <header className="text-center mb-16">
          <motion.h1
            className="text-5xl font-extrabold text-white leading-tight"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
          >
            Careers at FAANG
          </motion.h1>
          <motion.p
            className="text-gray-300 mt-4 text-lg"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.2 }}
          >
            Explore exciting opportunities and join our mission to revolutionize career prep.
          </motion.p>
        </header>

        {/* Cards Section */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {loading
            ? Array(6)
                .fill()
                .map((_, index) => (
                  <motion.div
                    key={index}
                    className="relative bg-[#2c2949] backdrop-blur-md border border-gray-700 rounded-3xl shadow-lg hover:shadow-2xl transition-shadow duration-300 p-8 flex flex-col text-white"
                    style={{
                      boxShadow:
                        "0 8px 20px rgba(0, 0, 0, 0.2), 0 4px 10px rgba(255, 255, 255, 0.1)",
                    }}
                    initial={{ opacity: 0, y: 30 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.6 }}
                  >
                    <div className="w-10 h-10 bg-gray-600 rounded-full mb-4 animate-pulse"></div>
                    <div className="h-5 w-3/4 bg-gray-600 rounded mb-2 animate-pulse"></div>
                    <div className="h-3 w-1/2 bg-gray-600 rounded mb-6 animate-pulse"></div>
                    <div className="h-4 w-full bg-gray-600 rounded animate-pulse mb-2"></div>
                    <div className="h-4 w-5/6 bg-gray-600 rounded animate-pulse mb-auto"></div>
                    <div className="flex justify-between items-center mt-4">
                      <div className="w-10 h-10 bg-gray-600 rounded-full animate-pulse"></div>
                      <div className="w-20 h-8 bg-gray-600 rounded animate-pulse"></div>
                    </div>
                  </motion.div>
                ))
            : [
                // Prioritize these roles and sort explicitly
                ...roles
                  .filter((role) => ["Marketing Intern", "Student Ambassador"].includes(role.PK))
                  .sort((a, b) => (a.PK === "Marketing Intern" ? -1 : 1)),
                // Add the rest
                ...roles.filter(
                  (role) => !["Marketing Intern", "Student Ambassador"].includes(role.PK)
                ),
              ].map((role) => {
                // Retrieve the applied job from localStorage
                const appliedJobs = JSON.parse(localStorage.getItem("appliedJobs")) || [];

                // Check if the current job has already been applied
                const isApplied = appliedJobs.some((job) => job.job_id === role.id);

                // Get the likes and clicked status for this role
                const roleLikes = likes[role.id] || 0;
                const isClicked = clicked[role.id] || false;

                
                return (
                  <motion.div
                    key={role.id}
                    className="relative bg-[#2c2949] backdrop-blur-md border border-gray-700 rounded-3xl shadow-lg hover:shadow-2xl transition-shadow duration-300 p-8 flex flex-col text-white"
                    style={{
                      boxShadow:
                        "0 8px 20px rgba(0, 0, 0, 0.2), 0 4px 10px rgba(255, 255, 255, 0.1)",
                    }}
                    initial={{ opacity: 0, y: 30 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.6, ease: "easeOut" }}
                    viewport={{ once: true }}
                  >
                    {/* Add lighting effect only for Marketing roles */}
                    {role.PK === "Marketing Intern" && (
                      <motion.div
                        className="absolute inset-0 bg-gradient-to-r from-[#00FFEE] via-[#413E8B] to-[#FF00FF] opacity-10 blur-xl z-[-3]"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 0.3 }}
                        transition={{ duration: 1.5 }}
                      ></motion.div>
                    )}

                    {/* Title Section with Icon */}
                    <div className="flex items-center mb-4">
                      <div className="flex items-center justify-center w-10 h-10 bg-gradient-to-br from-indigo-500 to-purple-500 rounded-full shadow-md mr-4">
                        {renderIcon(role.icon)} {/* Render the dynamic icon */}
                      </div>
                      <h2 className="text-xl font-semibold">{role.title}</h2>
                    </div>

                    {/* Nickname */}
                    <p className="text-indigo-300 text-sm font-medium mb-2">{role.nickname}</p>

                    {/* Card Description */}
                    <p className="text-gray-300 text-sm mt-2 mb-8 leading-relaxed">
                      {role.description}
                    </p>

                    {/* Button Section */}
                    <div className="mt-auto flex justify-between items-center">
                      {/* Like Button with Like Count */}
                      <div className="flex items-center space-x-3">
                        <button
                          onClick={() => handleLike(role.id, role.PK)}
                          className="flex items-center justify-center w-10 h-10 hover:from-indigo-500 hover:to-purple-700 text-white rounded-full shadow-md transition-transform transform hover:scale-105"
                          style={{
                            background: isClicked
                              ? "radial-gradient(circle, rgb(52 44 98) 0%, rgb(137 106 255) 100%)"
                              : "radial-gradient(112.32% 83.8% at 50.41% 50%, rgba(0, 0, 0, 0) 0%, rgb(255 255 255 / 37%) 100%)",
                          }}
                          aria-label="Like"
                        >
                          <FaThumbsUp />
                        </button>
                        <span className="text-sm text-gray-300">{roleLikes} likes</span>
                      </div>

                      {/* Apply Button or Already Applied Button */}
                      {isApplied ? (
                        <button
                          className="inline-flex items-center justify-center bg-gray-500 text-white font-medium py-2 px-5 rounded-[17px] shadow-md"
                          disabled
                        >
                          Already Applied
                          <Icons.FaCheck className="ml-2" />
                        </button>
                      ) : (
                        <button
                          onClick={() => handleApplyClick(role)} // Pass the role object
                          className="inline-flex items-center justify-center bg-gradient-to-r from-indigo-500 to-indigo-800 hover:from-indigo-500 hover:to-indigo-700 text-white font-medium py-2 px-5 rounded-[17px] shadow-md transition-transform transform hover:scale-105"
                        >
                          Apply Now
                          <FaArrowRight className="ml-2" />
                        </button>
                      )}
                    </div>
                  </motion.div>
                );
              })}
        </div>
      </div>
    </>
  );
};

export default CareersPage;
