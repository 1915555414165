import React from "react";
import { useInView } from "react-intersection-observer";

const Section = ({ children }) => {
  const { ref, inView } = useInView({ triggerOnce: true, threshold: 0 });
  return (
    <div
      ref={ref}
      className={`transition-opacity duration-700 transform ${inView ? "opacity-100 translate-y-0" : "opacity-0 translate-y-8"
        }`}
    >
      {children}
    </div>
  );
};

const TermsAndPrivacy = () => {
  return (
    <div className="max-w-[1340px] !pt-12 mx-auto py-16 px-6 lg:px-8 flex flex-col items-center">
      <div className="w-full max-w-[1000px] mx-auto">
        {/* Header Section */}
        <Section>
          <h1 className="text-[26px] font-bold text-center mb-4 sm:text-[36px] bg-gradient-to-r from-[#E1E1E1] to-[#929292] bg-clip-text text-transparent">
            Terms of Use
          </h1>
          <p className="text-center text-[#d1d1d1] text-[16px] mb-12">
            Welcome to FAANG operated by FAANG, Inc. Please read our Terms of
            Use carefully before using our platform.
          </p>
        </Section>

        {/* Terms of Use Section */}
        <Section>
          <h2 className="text-[22px] font-semibold text-white mb-6">
            Terms of Use
          </h2>
          <div className="mb-8 p-6 bg-[#53535347] bg-opacity-60 backdrop-blur-lg backdrop-saturate-150 rounded-xl shadow-md">
            <p className="text-[#d1d1d1] text-[16px] leading-relaxed">
              Welcome to FAANG operated by FAANG, Inc., a Delaware corporation
              (“FAANG”, “us”, “our”, and “we”). FAANG is a web and mobile
              application (“App”) that allows users to create an AI interview
              agent, upload their resumes, and participate in mock interviews
              (“Services”). (The Services and App are collectively referred to
              as the “Platform”.)
            </p>
            <p className="mt-4 text-[#d1d1d1] text-[16px] leading-relaxed">
              These Terms of Use (“Terms”) set forth the legally binding terms
              for your (“User”, “you”, and “your”) use of the Platform. By
              accessing or using the Platform, you are accepting these Terms and
              you represent and warrant that you have the right, authority, and
              capacity to enter into these Terms. If you do not agree with all
              of the provisions of these Terms, do not access and/or use the
              Platform. You may not access or use the Platform or accept the
              Terms if you are not at least 18 years old.
            </p>
          </div>

          {/* Privacy Policy */}
          <div className="mb-8 p-6 bg-[#53535347] bg-opacity-60 backdrop-blur-lg backdrop-saturate-150 rounded-xl shadow-md">
            <h3 className="text-[20px] font-semibold text-white mb-4">
              Privacy Policy
            </h3>
            <p className="text-[#d1d1d1] text-[16px] leading-relaxed">
              The Company respects the privacy of its users. Please refer to the
              Company’s Privacy Policy (faangai.com/privacy), which explains how
              we collect, use, and disclose information that pertains to your
              privacy. When you access or use the Platform, you signify your
              agreement to this Privacy Policy and consent to the processing of
              your personal information in accordance with its terms.
            </p>
          </div>

          {/* Platform Features and Content */}
          <div className="mb-8 p-6 bg-[#53535347] bg-opacity-60 backdrop-blur-lg backdrop-saturate-150 rounded-xl shadow-md">
            <h3 className="text-[20px] font-semibold text-white mb-4">
              Platform Features and Content
            </h3>
            <p className="text-[#d1d1d1] text-[16px] leading-relaxed">
              Our Platform may provide informational and educational content.
              All content on the Platform is for informational and educational
              purposes only, is general in nature, and under no circumstance
              does the content on our Platform undertake to give advice for
              your particular situation. The content on our Platform is not
              meant to be relied upon by Platform visitors in reliance on making
              any decisions. You acknowledge that you are participating
              voluntarily in using our Platform or in any of our emails,
              programs, services, and/or products. In the event that you use the
              information provided through our Platform, emails, programs,
              Services, and/or products, we assume no responsibility.
            </p>
            <p className="mt-4 text-[#d1d1d1] text-[16px] leading-relaxed">
              There is no guarantee that you will experience specific results
              from any of our Platform and you accept the risk that results
              differ by individual.
            </p>
            <p className="mt-4 text-[#d1d1d1] text-[16px] leading-relaxed">
              Consent to AI Interview Mockups and Assessments. By using the Platform, you explicitly consent to participate in mock AI interviews and agree to receive feedback based on the data you submit, including your resume and responses during mock interviews. These assessments are intended for self-improvement and informational purposes only, and FAANG makes no guarantees about your performance or results.

            </p>
            <p className="mt-4 text-[#d1d1d1] text-[16px] leading-relaxed">
              Consent to Audio and Video Recording. You acknowledge and consent that your interactions, including mock interviews and coffee calls, may be recorded in audio (and video, depending on the chosen features). These recordings will be stored and may be used for personalized feedback, assessments, and improving the Platform. You agree to the collection and use of these recordings in accordance with our Privacy Policy.
            </p>
            <p className="mt-4 text-[#d1d1d1] text-[16px] leading-relaxed">
              Automated Decision-Making and Profiling. The Platform may assess users' skills based on their input, such as the uploaded CV and performance in mock interviews. This assessment may result in profiles highlighting skills such as proficiency in Python or communication abilities. These profiles are private to the user and are not shared with third parties. By using the Platform, you consent to the automated processing of your personal information for the purposes of generating such profiles. You further acknowledge and agree to hold FAANG harmless from any claims or liabilities arising from the use of this profiling, including but not limited to any impacts or consequences resulting from the assessment of your
              skills. Please note that this process constitutes profiling under applicable data protection laws, and you have the right to object to or request further clarification on how these profiles are created and used. Additional rights related to profiling and automated decision-making are detailed in our Privacy Policy.
            </p>
            <p className="mt-4 text-[#d1d1d1] text-[16px] leading-relaxed">
              Use of Data for Service Improvement. You understand that FAANG may use the data, including audio and video recordings, for service enhancement, to train AI models, and to improve the Platform. This data will be processed and stored in compliance with applicable privacy regulations, such as GDPR, and our Privacy Policy.
            </p>
            <p className="mt-4 text-[#d1d1d1] text-[16px] leading-relaxed">
              No Guarantee of Results. The feedback, tips, and reports provided by our Platform, including AI-generated content, are for informational and educational purposes only. You acknowledge that, while FAANG strives to provide accurate and useful feedback, there is no guarantee of specific results from using the Platform, including improvements in interview performance or job placement. The feedback and assessments provided are based on your inputs and may differ from real-world results.

            </p>
            <p className="mt-4 text-[#d1d1d1] text-[16px] leading-relaxed">
              Humor and Roasting Feature Disclaimer. By using the 'Roast Me' feature, you acknowledge and agree that the content generated by the AI is intended solely for entertainment and humorous purposes. The Company does not endorse or encourage any harmful or offensive behavior, and the AI-generated content is not intended to be taken seriously. While we strive to ensure that the content is lighthearted and fun, we understand that humor can be subjective. By participating in this feature, you agree to waive any claims against the FAANG related to the content generated by this feature, including but not limited to claims of personal offense, harm, or injury.

            </p>
            <p className="mt-4 text-[#d1d1d1] text-[16px] leading-relaxed">
              Hold Harmless Agreement. You agree to hold FAANG harmless from any claims, damages, or liability arising out of your use of the Platform, including the use of AI interviews, assessments, and any audio or video recordings. By using the Platform, you accept full responsibility for any risks associated with participating in these services and waive any claims related to the outcomes of these activities.
            </p>
            <p className="mt-4 text-[#d1d1d1] text-[16px] leading-relaxed">

            </p>
            <p className="mt-4 text-[#d1d1d1] text-[16px] leading-relaxed">

            </p>
            <p className="mt-4 text-[#d1d1d1] text-[16px] leading-relaxed">

            </p>
            <p className="mt-4 text-[#d1d1d1] text-[16px] leading-relaxed">

            </p>
          </div>

          {/* Add Additional Sections */}
          <div className="mb-8 p-6 bg-[#53535347] bg-opacity-60 backdrop-blur-lg backdrop-saturate-150 rounded-xl shadow-md">
            <h3 className="text-[20px] font-semibold text-white mb-4">
              Accounts
            </h3>
            <p className="text-[#d1d1d1] text-[16px] leading-relaxed">
              In order to use certain features of the Platform, you must
              register for an account with us (“your Account”) and provide
              certain information about yourself as prompted by the
              registration form. You represent and warrant that (a) all required
              registration information you submit is truthful and accurate; and
              (b) you will maintain the accuracy of such information. You may
              delete your Account at any time, for any reason, by following the
              instructions on the Platform.
            </p>
            <p className="mt-4 text-[#d1d1d1] text-[16px] leading-relaxed">
              Account Responsibilities. You are responsible for maintaining the confidentiality of your Account login information and are fully responsible for all activities that occur under your Account. You agree to immediately notify us of any unauthorized use, or suspected unauthorized use, of your Account or any other breach of security. We cannot and will not be liable for any loss or damage arising from your failure to comply with the above requirements.

            </p>

          </div>

          {/* Payment Terms */}
          <div className="mb-8 p-6 bg-[#53535347] bg-opacity-60 backdrop-blur-lg backdrop-saturate-150 rounded-xl shadow-md">
            <h3 className="text-[20px] font-semibold text-white mb-4">
              Payment Terms
            </h3>
            <p className="text-[#d1d1d1] text-[16px] leading-relaxed">
              Certain aspects of the Platform may be provided for a fee or other
              charge. One-time purchases will be charged at the time of the
              transaction. FAANG may add new services for additional fees and
              charges, and amend fees and charges for existing services, at any
              time in its sole discretion.
            </p>
            <p className="mt-4 text-[#d1d1d1] text-[16px] leading-relaxed">
              Payment Information; Taxes. All information that you provide in connection with a purchase or transaction must be accurate, complete, and current. You agree to pay all charges incurred by users of your credit card, debit card, or other payment method used in connection with a purchase or transaction at the prices in effect when such charges are incurred. You will pay any applicable taxes, if any, relating to any such purchases or transactions.
            </p>
            <p className="mt-4 text-[#d1d1d1] text-[16px] leading-relaxed">
              No Refunds. All purchases, including the purchase of coins, are final and non-refundable. Once a transaction is completed, FAANG will not provide refunds, credits, or adjustments for any unused coins or unused time.
            </p>
            <p className="mt-4 text-[#d1d1d1] text-[16px] leading-relaxed">
              Cancellation. If you cancel your Account, any unused coins remaining in your Account will be forfeited, and no refund will be issued. It is your responsibility to use your purchased coins before Account cancellation.
            </p>
            <p className="mt-4 text-[#d1d1d1] text-[16px] leading-relaxed">
              Chargebacks. If you have a dispute concerning any payment transaction, please contact us at support@faangai.com. If, at any time, you contact your bank or credit card company to reject the charge of any payable fees (“Chargeback”), this act will be considered a breach of your payment obligations, and we reserve the right to automatically terminate your use of the Platform. We reserve the right to dispute any Chargeback and take all reasonable action to authorize the transaction. In the event of a Chargeback, your User Account may be terminated and any files, data, or content contained in your Account may be subject to cancellation. We will not be liable for any loss of files, data or content as a result of a Chargeback. Without limiting other available remedies, you must pay FAANG upon demand for amounts owed under these Terms, plus interest on the outstanding amount at the lesser of one percent (1%) per month or the maximum interest allowed by applicable law, plus attorneys’ fees and other costs of collection to the extent permitted by applicable law.
            </p>
          </div>
          <div className="mb-8 p-6 bg-[#53535347] bg-opacity-60 backdrop-blur-lg backdrop-saturate-150 rounded-xl shadow-md">
            <h3 className="text-[20px] font-semibold text-white mb-4">
              Mobile Apps

            </h3>
            <p className="text-[#d1d1d1] text-[16px] leading-relaxed">
              We may provide a mobile application (“Mobile App”) as another
              platform for accessing the Services. To use the Mobile App you
              must have a mobile device that is compatible with the Mobile App.
              We do not warrant that the Mobile App will be compatible with your
              mobile device.
            </p>
            <p className="mt-4 text-[#d1d1d1] text-[16px] leading-relaxed">
              You may not: a) modify, disassemble, decompile or reverse engineer
              the Mobile App; b) rent, lease, loan, resell, sublicense,
              distribute or otherwise transfer the Mobile App to any
              third-party; c) make any copies of the Mobile App; d) remove,
              circumvent, disable, damage or otherwise interfere with
              security-related features of the Mobile App, features that prevent
              or restrict use or copying of any content accessible through the
              Mobile App, or features that enforce limitations on use of the
              Mobile App; or e) delete the copyright and other proprietary
              rights notices on the Mobile App.
            </p>
            <p className="mt-4 text-[#d1d1d1] text-[16px] leading-relaxed">
              You acknowledge that we may from time to time issue upgraded
              versions of the Mobile App, and may upgrade automatically to your
              mobile device the version of the Mobile App that you are using.
              You consent to such automatic upgrading and agree that these Terms
              of Use will apply to all such upgrades. We may elect to provide
              you with support or modifications for the Platform (“Support”), in
              our sole discretion, and we may change, reduce or terminate such
              Support at any time without notice to you. We reserve the right to
              charge fees for Support. Any use of third-party software provided
              in connection with the Platform will be governed by such third
              parties’ licenses and not by these Terms of Use.
            </p>
            <p className="mt-4 text-[#d1d1d1] text-[16px] leading-relaxed">
              <strong>App Stores.</strong> You acknowledge and agree that the
              availability of the Mobile Apps is dependent on the third-party
              websites from which you download the Mobile Apps, e.g., the App
              Store from Apple or the Android app market from Google (each an
              “App Store”). You acknowledge that these Terms are between you and
              FAANG and not with an App Store. Each App Store may have its own
              terms and conditions to which you must agree before downloading
              Mobile Apps from it. You agree to comply with, and your license to
              use the Mobile Apps is conditioned upon your compliance with, such
              App Store terms and conditions. To the extent such other terms and
              conditions from such App Store are less restrictive than, or
              otherwise conflict with, the terms and conditions of these Terms,
              the more restrictive or conflicting terms and conditions in these
              Terms apply.
            </p>
          </div>
          <div className="mb-8 p-6 bg-[#53535347] bg-opacity-60 backdrop-blur-lg backdrop-saturate-150 rounded-xl shadow-md">
            <h3 className="text-[20px] font-semibold text-white mb-4">
              Rights and Licenses
            </h3>
            <p className="text-[#d1d1d1] text-[16px] leading-relaxed">
              License to Use Platform. We grant you a non-transferable, non-exclusive, right to access and use the Platform for your personal use.
            </p>
            <h3 className="text-[20px] font-semibold text-white mb-4 mt-6">
              Certain Restrictions
            </h3>
            <p className="text-[#d1d1d1] text-[16px] leading-relaxed">
              The rights granted to you in these Terms are subject to the following
              restrictions:
            </p>
            <ul className="list-disc pl-6 text-[#d1d1d1] text-[16px] leading-relaxed mt-4">
              <li>
                (a) you will not license, sell, rent, lease, transfer, assign,
                distribute, host, or otherwise commercially exploit the Platform;
              </li>
              <li>
                (b) you will not modify, make derivative works of, disassemble, reverse
                compile or reverse engineer any part of the Platform;
              </li>
              <li>
                (c) you will not access the Platform in order to build a similar or
                competitive service; and
              </li>
              <li>
                (d) except as expressly stated in these terms, no part of the Platform
                may be copied, reproduced, distributed, republished, downloaded,
                displayed, posted or transmitted in any form or by any means.
              </li>
            </ul>
            <p className="text-[#d1d1d1] text-[16px] leading-relaxed mt-4">
              Any future release, update, or other addition to functionality of the
              Platform will be subject to these Terms. All copyright and other
              proprietary notices on any Platform content must be retained on all
              copies.
            </p>
            <h3 className="text-[20px] font-semibold text-white mb-4 mt-6">
              Modification
            </h3>
            <p className="text-[#d1d1d1] text-[16px] leading-relaxed">
              We reserve the right, at any time, to modify, suspend, or discontinue the
              Platform with or without notice. You agree that we will not be liable to
              you or to any third party for any modification, suspension, or
              discontinuance of the Platform, except and if otherwise expressly set
              forth in these Terms.
            </p>
            <h3 className="text-[20px] font-semibold text-white mb-4 mt-6">
              Ownership of the Platform
            </h3>
            <p className="text-[#d1d1d1] text-[16px] leading-relaxed">
              Excluding your User Content (defined below), you acknowledge that all the
              intellectual property rights, including copyrights, patents, trademarks,
              and trade secrets, in the Platform, including the Platform, are owned by
              us or our licensors. The provision of the Platform does not transfer to
              you or any third party any rights, title or interest in or to the
              intellectual property rights. We reserve all rights not granted in these
              Terms.
            </p>
          </div>
          <div className="mb-8 p-6 bg-[#53535347] bg-opacity-60 backdrop-blur-lg backdrop-saturate-150 rounded-xl shadow-md">
            <h3 className="text-[20px] font-semibold text-white mb-4">
              User Content

            </h3>
            <p className="text-[#d1d1d1] text-[16px] leading-relaxed">
              “User Content” means any and all information and content that a user
              submits to or posts on the Platform. You will own your User Content, with
              the understanding that you agree that we may use and reproduce the User
              Content you make available on our Platform. You are solely responsible for
              the User Content that you post, upload, link to, or otherwise make
              available via the Service. We reserve the right to remove any User Content
              from the Service at our discretion.
            </p>
            <p className="mt-4 text-[#d1d1d1] text-[16px] leading-relaxed">
              The following rules pertain to User Content. By transmitting and
              submitting any User Content while using the Service, you agree as follows:
            </p>
            <ul className="list-disc list-inside text-[#d1d1d1] mt-4 text-[16px] leading-relaxed">
              <li>
                You are solely responsible for your account and the activity that occurs
                while signed in to or while using your account.
              </li>
              <li>
                You will not submit content that is copyrighted or subject to
                third-party proprietary rights, including privacy, publicity, trade
                secret, etc., unless you are the owner of such rights or have the
                appropriate permission from their rightful owner to specifically submit
                such content.
              </li>
              <li>You will abide by our Acceptable Use Policy below.</li>
              <li>
                You affirm we have the right to determine whether any of your User
                Content submissions are appropriate and comply with these Terms of Use,
                remove any and/or all of your submissions, and terminate your account
                with or without prior notice.
              </li>
            </ul>
            <p className="mt-4 text-[#d1d1d1] text-[16px] leading-relaxed">
              You understand and agree that any liability, loss or damage that occurs as
              a result of the use of any User Content that you make available or access
              through your use of the Service is solely your responsibility. We are not
              responsible for any public display or misuse of your User Content. We do
              not, and cannot, pre-screen or monitor all User Content. However, at our
              discretion, we, or technology we employ, may monitor and/or record your
              interactions with the Service.
            </p>
            <p className="mt-4 text-[#d1d1d1] text-[16px] leading-relaxed">
              <strong>License.</strong> You grant, and you represent and warrant that
              you have the right to grant, to us an irrevocable, nonexclusive,
              royalty-free and fully paid, worldwide license to reproduce, distribute,
              publicly display and perform, prepare derivative works of, incorporate
              into other works, and otherwise use your User Content, and to grant
              sublicenses of the foregoing, solely for the purposes of including your
              User Content in the Platform and Services. You agree to irrevocably waive
              (and cause to be waived) any claims and assertions of moral rights or
              attribution with respect to your User Content.
            </p>
          </div>

          <div className="mb-8 p-6 bg-[#53535347] bg-opacity-60 backdrop-blur-lg backdrop-saturate-150 rounded-xl shadow-md">
            <h2 className="text-[22px] font-semibold text-white mb-6">
              Acceptable Use Policy
            </h2>
            <p className="text-[#d1d1d1] text-[16px] leading-relaxed">
              Your permission to use the Platform is conditioned upon the following
              restrictions: You agree that you will not under any circumstances:
            </p>
            <ul className="list-disc pl-6 mt-4 text-[#d1d1d1] text-[16px] leading-relaxed">
              <li>
                Post any information that is abusive, threatening, obscene,
                defamatory, libelous, or racially, sexually, religiously, or otherwise
                objectionable and offensive.
              </li>
              <li>
                Use the service for any unlawful purpose or for the promotion of
                illegal activities.
              </li>
              <li>Attempt to, or harass, abuse or harm another person or group.</li>
              <li>Use another user’s account without permission.</li>
              <li>
                Provide false or inaccurate information or impersonate another person
                when registering an account.
              </li>
              <li>
                Interfere or attempt to interfere with the proper functioning of the
                Service.
              </li>
              <li>
                Make any automated use of the system, or take any action that we deem
                to impose or to potentially impose an unreasonable or disproportionately
                large load on our servers or network infrastructure.
              </li>
              <li>
                Use the Platform or any of its contents to advertise or solicit, for
                any commercial purpose or to compete, directly or indirectly, with our
                Service.
              </li>
              <li>
                Bypass any robot exclusion headers or other measures we take to
                restrict access to the Service or use any software, technology, or
                device to scrape, spider, or crawl the Service or harvest or manipulate
                data.
              </li>
              <li>
                Publish or link to malicious content intended to damage or disrupt
                another user’s browser or computer.
              </li>
            </ul>
            <h3 className="text-[20px] font-semibold text-white mt-6 mb-4">
              Feedback
            </h3>
            <p className="text-[#d1d1d1] text-[16px] leading-relaxed">
              If you provide us any feedback or suggestions regarding the Platform
              (“Feedback”), you assign to us all rights in the Feedback and agree that
              we will have the right to use the Feedback and related information in any
              manner we deem appropriate. We will treat any Feedback you provide to us
              as non-confidential and non-proprietary. You agree that you will not
              submit to us any information or ideas that you consider to be
              confidential or proprietary.
            </p>
          </div>


          <div className="mb-8 p-6 bg-[#53535347] bg-opacity-60 backdrop-blur-lg backdrop-saturate-150 rounded-xl shadow-md">
            <h2 className="text-[22px] font-semibold text-white mb-6">
              Maintenance, Updates, and Discontinuance
            </h2>
            <p className="text-[#d1d1d1] text-[16px] leading-relaxed">
              From time to time, FAANG may update, modify, expand, or improve the
              Platform to enhance its functionality and performance. We may also, at
              any time and without prior notice, choose to disable certain features,
              suspend access, or cease operation of part or all of the Platform. Your
              use of the Platform does not guarantee the ongoing availability of any
              particular features or functionality.
            </p>
            <p className="mt-4 text-[#d1d1d1] text-[16px] leading-relaxed">
              Any modifications or discontinuation of the Platform or specific features
              will be determined at our sole discretion and without any obligation or
              liability to you. FAANG may, at any time, suspend, limit, or discontinue
              access to content, features, or online servers associated with the
              Platform for reasons including maintenance, upgrades, or service changes,
              without notice or liability.
            </p>
          </div>

          <div className="mb-8 p-6 bg-[#53535347] bg-opacity-60 backdrop-blur-lg backdrop-saturate-150 rounded-xl shadow-md">
            <h2 className="text-[22px] font-semibold text-white mb-6">Indemnity</h2>
            <p className="text-[#d1d1d1] text-[16px] leading-relaxed">
              You agree to indemnify and hold us (and our officers, employees, and
              agents) harmless, including costs and attorneys’ fees, from any claim or
              demand made by any third party due to or arising out of (a) your use of
              the Platform and the Services, (b) your User Content, (c) your violation
              of these Terms; or (d) your violation of applicable laws or regulations.
              We reserve the right, at your expense, to assume the exclusive defense and
              control of any matter for which you are required to indemnify us and you
              agree to cooperate with our defense of these claims. You agree not to
              settle any matter without our prior written consent. We will use
              reasonable efforts to notify you of any such claim, action, or proceeding
              upon becoming aware of it.
            </p>
          </div>


          <div className="mb-8 p-6 bg-[#53535347] bg-opacity-60 backdrop-blur-lg backdrop-saturate-150 rounded-xl shadow-md">
            <h2 className="text-[22px] font-semibold text-white mb-6">
              Links to Other Platforms and/or Materials
            </h2>
            <p className="text-[#d1d1d1] text-[16px] leading-relaxed">
              <strong>Third-Party Sites, and Third-Party Content.</strong> As part of
              the Service, we may provide you with convenient links to third-party
              website(s) (“Third-Party Sites”) as well as content or items belonging to
              or originating from third parties such as applications, software, or
              content (collectively the “Third-Party Content”). Users may also include
              links to their website or other Third-Party Sites on their listings. These
              links are provided as a courtesy to Users. We have no control over
              Third-Party Sites and Third-Party Content or the promotions, materials,
              information, goods, or services available on these Third-Party Sites or
              Third-Party Content. If you decide to leave the Platform and access the
              Third-Party Sites or to use or install any Third-Party Content, you do so
              at your own risk and you should be aware that our terms and policies no
              longer govern. You should review the applicable terms and policies,
              including privacy and data gathering practices, of any site to which you
              navigate from the Platform or relating to any applications you use or
              install from the site.
            </p>
            <p className="mt-4 text-[#d1d1d1] text-[16px] leading-relaxed">
              <strong>Links to Our Platform.</strong> You are permitted to link to our
              Platform for non-commercial purposes, provided that you do so in a way
              that is fair and legal and does not damage our reputation. You may not
              link to our Platform in such a way as to suggest any form of association,
              approval, or endorsement on our part without our express written consent.
              You may not deep-link to any page of this site for any purpose whatsoever
              unless the link is expressly authorized in writing by us. We reserve the
              right to withdraw permission for any link.
            </p>
          </div>



          <div className="mb-8 p-6 bg-[#53535347] bg-opacity-60 backdrop-blur-lg backdrop-saturate-150 rounded-xl shadow-md">
            <h2 className="text-[22px] font-semibold text-white mb-6">Release</h2>
            <p className="text-[#d1d1d1] text-[16px] leading-relaxed">
              You release and forever discharge us (and our officers, employees, agents, successors, and assigns) from, and waive and relinquish, each and every past, present and future dispute, claim, controversy, demand, right, obligation, liability, action and cause of action of every kind and nature (including personal injury, death, and property damage), that has arisen or arises directly or indirectly out of, or relates directly or indirectly to, any interactions with, or act or omission of, other service users or Third-Party Sites, and Third-Party Content, and any third-party service provider that processes your data. YOU KNOWINGLY AND VOLUNTARILY WAIVE ANY PROTECTION THAT YOU MIGHT HAVE BY VIRTUE OF ANY LAW OR STATUTE PROVIDING THAT A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE RELEASING PARTY DOES NOT KNOW OR SUSPECT TO EXIST IN ITS FAVOR AT THE TIME OF EXECUTING THE RELEASE AND THAT, IF KNOWN BY IT, WOULD HAVE MATERIALLY AFFECTED ITS SETTLEMENT WITH THE RELEASED PARTY.
            </p>
          </div>

          <div className="mb-8 p-6 bg-[#53535347] bg-opacity-60 backdrop-blur-lg backdrop-saturate-150 rounded-xl shadow-md">
            <h2 className="text-[22px] font-semibold text-white mb-6">Disclaimers</h2>

            <p className="text-[#d1d1d1] text-[16px] leading-relaxed">
              THE SERVICES, INCLUDING THE SITE, ARE PROVIDED “AS-IS” AND “AS AVAILABLE” AND WE EXPRESSLY DISCLAIM ANY WARRANTIES AND CONDITIONS OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING THE WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, QUIET ENJOYMENT, ACCURACY, OR NON-INFRINGEMENT. WE MAKE NO WARRANTY THAT THE SERVICES: (a) WILL MEET YOUR REQUIREMENTS; (b) WILL BE AVAILABLE ON AN UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE BASIS; (c) WILL BE ACCURATE, RELIABLE, FREE OF VIRUSES OR OTHER HARMFUL CODE, COMPLETE, LEGAL, OR SAFE OR (d) THAT THE SERVICES WILL BE TO YOUR SATISFACTION.
            </p>
            <p className="mt-4 text-[#d1d1d1] text-[16px] leading-relaxed">
              FAANG makes no representation that materials on this Platform are appropriate or available for use in locations outside the European Union (EU). Access to this site from countries or territories where such access is illegal is prohibited. Those who choose to access this Platform outside the EU do so on their own initiative and are responsible for compliance with local laws.
            </p>
            <p className="mt-4 text-[#d1d1d1] text-[16px] leading-relaxed">
              SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES, SO THE ABOVE EXCLUSION MAY NOT APPLY TO YOU.
            </p>
          </div>

          <div className="mb-8 p-6 bg-[#53535347] bg-opacity-60 backdrop-blur-lg backdrop-saturate-150 rounded-xl shadow-md">
            <h2 className="text-[22px] font-semibold text-white mb-6">
              Limitation on Liability
            </h2>
            <p className="text-[#d1d1d1] text-[16px] leading-relaxed">
              IN NO EVENT WILL WE BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY LOST PROFIT OR ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL OR PUNITIVE DAMAGES ARISING FROM OR RELATING TO THESE TERMS OR YOUR USE OF, OR INABILITY TO USE, THE SERVICES, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. ACCESS TO, AND USE OF, THE SERVICES ARE AT YOUR OWN DISCRETION AND RISK, AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR LOSS OF DATA RESULTING THEREFROM.
            </p>
            <p className="mt-4 text-[#d1d1d1] text-[16px] leading-relaxed">
              IN NO EVENT WILL FAANG’S TOTAL LIABILITY TO YOU FOR ALL DAMAGES, LOSSES, AND CAUSES OF ACTION (WHETHER IN CONTRACT, TORT (INCLUDING, BUT NOT LIMITED TO, NEGLIGENCE), OR OTHERWISE EXCEED THE AMOUNTS YOU’VE PAID FAANG IN THE PRIOR 12 MONTHS (IF ANY). THE EXISTENCE OF MORE THAN ONE CLAIM WILL NOT ENLARGE THIS LIMIT.
            </p>
            <p className="mt-4 text-[#d1d1d1] text-[16px] leading-relaxed">
              SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OF CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU.
            </p>
          </div>



          <div className="mb-8 p-6 bg-[#53535347] bg-opacity-60 backdrop-blur-lg backdrop-saturate-150 rounded-xl shadow-md">
            <h2 className="text-[22px] font-semibold text-white mb-6">
              Term and Termination
            </h2>
            <p className="text-[#d1d1d1] text-[16px] leading-relaxed">
              Subject to this Section, these Terms will remain in full force and effect
              while you use the Platform. We may (a) suspend your rights to use the
              Platform and/or Services (including your Account) or (b) terminate these
              Terms, at any time for any reason at our sole discretion, including for
              any use of the Platform in violation of these Terms. Upon termination of
              these Terms, your Account and right to access and use the Platform will
              terminate immediately. You understand that any termination of your
              Account involves deletion of your User Content from our live databases.
              We will not have any liability whatsoever to you for any termination of
              these Terms, including for termination of your Account or deletion of
              your User Content. Upon termination of these Terms, all of the provisions
              will terminate except those that by their nature should survive.
            </p>
          </div>

          <div className="mb-8 p-6 bg-[#53535347] bg-opacity-60 backdrop-blur-lg backdrop-saturate-150 rounded-xl shadow-md">
            <h2 className="text-[22px] font-semibold text-white mb-6">
              Legal Disputes
            </h2>
            <p className="text-[#d1d1d1] text-[16px] leading-relaxed">
              PLEASE READ THIS SECTION CAREFULLY. IT AFFECTS YOUR RIGHTS AND WILL HAVE
              A SUBSTANTIAL IMPACT ON HOW CLAIMS YOU AND FAANG HAVE AGAINST EACH OTHER
              ARE RESOLVED.
            </p>
            <p className="mt-4 text-[#d1d1d1] text-[16px] leading-relaxed">
              You and FAANG agree that any claim or dispute at law or equity that has
              arisen or may arise between us relating in any way to or arising out of
              this or previous versions of these Terms, your use of or access to the
              Platform, or any products or services sold or purchased through the
              Platform, will be resolved in accordance with the provisions set forth in
              this Legal Disputes Section.
            </p>
            <p className="mt-4 text-[#d1d1d1] text-[16px] leading-relaxed">
              <strong>Choice of Law.</strong> These Terms are made under and will be
              governed by and construed in accordance with the laws of the state of
              Delaware, without giving effect to any principles that provide for the
              application of the law of another jurisdiction.
            </p>
            <p className="mt-4 text-[#d1d1d1] text-[16px] leading-relaxed">
              <strong>Claim Limitations.</strong> You agree that any cause of action
              arising out of or related to the Platform and Services must commence
              within one (1) year after the cause of action accrues. Otherwise, such
              cause of action is permanently barred.
            </p>
          </div>


          <div className="mb-8 p-6 bg-[#53535347] bg-opacity-60 backdrop-blur-lg backdrop-saturate-150 rounded-xl shadow-md">
            <h2 className="text-[22px] font-semibold text-white mb-6">
              Agreement to Arbitrate
            </h2>
            <p className="text-[#d1d1d1] text-[16px] leading-relaxed">
              You and FAANG each agree that any and all disputes or claims that have
              arisen or may arise between you and FAANG relating in any way to or
              arising out of this or previous versions of these Terms, your use of or
              access to FAANG's Platform, or any products or services sold, offered, or
              purchased through our Platform will be resolved exclusively through final
              and binding arbitration, rather than in court.
            </p>
            <p className="mt-4 text-[#d1d1d1] text-[16px] leading-relaxed">
              The arbitration will be conducted by JAMS under its applicable rules and
              procedures, as modified by this agreement to Arbitrate located in
              Delaware. The arbitration will be conducted before one commercial
              arbitrator with substantial experience in resolving commercial contract
              disputes.
            </p>
            <p className="mt-4 text-[#d1d1d1] text-[16px] leading-relaxed">
              Your rights will be determined by a neutral arbitrator and not a judge or
              jury. You understand that arbitration procedures can be more limited than
              rules applicable in court. Arbitrator decisions are as enforceable as any
              court order and are subject to very limited review in court.
            </p>
            <p className="mt-4 text-[#d1d1d1] text-[16px] leading-relaxed">
              You and we must abide by the following rules: (a) ANY CLAIMS BROUGHT BY
              YOU OR US MUST BE BROUGHT IN THE PARTIES’ INDIVIDUAL CAPACITY, AND NOT AS
              A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE
              PROCEEDING; (b) THE ARBITRATOR MAY NOT CONSOLIDATE MORE THAN ONE PERSON’S
              CLAIMS, MAY NOT OTHERWISE PRESIDE OVER ANY FORM OF A REPRESENTATIVE OR
              CLASS PROCEEDING, AND MAY NOT AWARD CLASS-WIDE RELIEF, (c) the arbitrator
              will honor claims of privilege and privacy recognized at law; (d) the
              arbitration will be confidential, and neither you nor we may disclose the
              existence, content or results of any arbitration, except as may be
              required by law or for purposes of enforcement of the arbitration award;
              (e) the arbitrator may award any individual relief or individual remedies
              that are permitted by applicable law; and (f) each side pays its own
              attorneys’ fees and expenses unless there is a statutory provision that
              requires the prevailing party to be paid its fees and litigation
              expenses, and then in such instance, the fees and costs awarded will be
              determined by the applicable law.
            </p>
            <p className="mt-4 text-[#d1d1d1] text-[16px] leading-relaxed">
              With the exception of subparts (a) and (b) in the paragraph above
              (prohibiting arbitration on a class or collective basis), if any part of
              this arbitration provision is deemed to be invalid, unenforceable or
              illegal, or otherwise conflicts with the Rules and Procedures, then the
              balance of this arbitration provision will remain in effect and will be
              construed in accordance with its terms as if the invalid, unenforceable,
              illegal or conflicting provision were not contained herein. If, however,
              either subpart (a) or (b) is found to be invalid, unenforceable or
              illegal, then the entirety of this arbitration provision will be null and
              void, and neither you nor we will be entitled to arbitration. If for any
              reason a claim proceeds in court rather than in arbitration, the dispute
              will be exclusively brought in the courts located in Delaware.
            </p>
          </div>


          <div className="mb-8 p-6 bg-[#53535347] bg-opacity-60 backdrop-blur-lg backdrop-saturate-150 rounded-xl shadow-md">
            <h2 className="text-[22px] font-semibold text-white mb-6">General</h2>
            <p className="text-[#d1d1d1] text-[16px] leading-relaxed">
              <strong>Changes to Terms.</strong> These Terms are subject to occasional
              revision, and if we make any substantial changes, we may notify you by
              prominently posting notice of the changes on our Platform. Any
              significant changes to these Terms will be effective 30 days after
              posting such notice. Continued use of our Platform or Services following
              notice of such changes will indicate your acknowledgement of such changes
              and agreement to be bound by the terms and conditions of such changes.
            </p>
            <p className="mt-4 text-[#d1d1d1] text-[16px] leading-relaxed">
              <strong>Copyright/Trademark Information.</strong> Copyright © 2024 FAANG,
              Inc. All rights reserved. All trademarks, logos and service marks
              (“Marks”) displayed on the Platform are our property or the property of
              other third parties. You are not permitted to use these Marks without our
              prior written consent or the consent of such third party which may own
              the Marks.
            </p>
            <p className="mt-4 text-[#d1d1d1] text-[16px] leading-relaxed">
              <strong>Contact Information:</strong>
              <br />
              FAANG
              <br />
              Email: support@faangai.com
              <br />
              Last Updated November 15, 2024
            </p>
          </div>


        </Section>



        {/* Additional Sections */}
        {/* Repeat above div structure for every section in your Terms of Use */}

      </div>
    </div>
  );
};

export default TermsAndPrivacy;
