import React, { useEffect, useRef, useState } from "react";
import { useInView } from "react-intersection-observer";
import bgGlow from "../../../assets/bg-glow2.png";
import SVGEarth from "../../../assets/SVG/SVGEarth";
import SalaryNinja from "../../../assets/salary_ninja3.png";
import BuddyRocket from "../../../assets/super_buddy.png";
import PersonalityRoaster from "../../../assets/personality_roaster2.png";
import { useNavigate } from "react-router-dom";

const templates = [
  {
    img: BuddyRocket,
    title: "Super Buddy",
    description:
      "Hey there, sidekick! I’m Super Buddy, your trusty guide to discovering your inner hero! Upload your resume, and I’ll reveal which superhero you are and why you’re destined for greatness! Ready to unleash your powers? 🚀",
    runs: 364,
    templatelink: "/templates/superbuddy",
    locked: false,
  },
  {
    img: PersonalityRoaster,
    title: "Personality Roaster",
    description:
      "Drop your CV, and let Personality Roaster turn up the heat with a roast you won’t forget! 🔥 Expect a hilarious, no-filter breakdown of your posts—because sometimes the truth is funnier than fiction! Ready for some laughs?",
    runs: 326,
    templatelink: "/templates/personalityroaster",
    locked: false,
  },
  {
    img: SalaryNinja,
    title: "Salary Negotiation Ninja",
    description:
      "Unlock expert tips to master salary negotiations and land your dream compensation. Collect 1000 coins to access this feature!",
    runs: 5,
    templatelink: "/templates/cvboosterbuddy",
    locked: true, // Locked state
  },
];

const HomeTemplates = () => {
  const [currentCard, setCurrentCard] = useState(0);
  const scrollRef = useRef(null);
  const navigate = useNavigate();

  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  const handleScroll = () => {
    const scrollLeft = scrollRef.current.scrollLeft;
    const width = scrollRef.current.clientWidth;
    const index = Math.round(scrollLeft / width);
    setCurrentCard(index);
  };

  useEffect(() => {
    const ref = scrollRef.current;
    if (ref) {
      ref.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (ref) {
        ref.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  const dotsLength = templates.length / 2.5;

  return (
    <section
      ref={ref}
      className={`flex flex-col justify-center items-center relative p-4 transition-all duration-700 ${inView ? "animate-fade-in-up" : "opacity-0"
        }`}
    >
      <img
        style={{ position: "absolute", opacity: "0.2", top: "-12%" }}
        src={bgGlow}
        alt="Background Glow"
      />
      <h1 className="landing-page-title-2 text-center-custom text-center text-slate-300 text-4xl font-bold leading-tight sm:text-3xl xs:text-2xl">
        Try our top Templates upvoted by our community
      </h1>
      <div
        ref={scrollRef}
        className="flex w-full space-x-4 my-10 overflow-x-auto scrollbar-hide snap-x snap-mandatory"
      >
        {templates.map((item, index) => (
          <div
            key={index}
            style={{
              background:
                "radial-gradient(112.32% 83.8% at 50.41% 50%, rgba(0, 0, 0, 0) 0%, rgba(152, 195, 189, 0.1) 100%)",
            }}
            className={`relative rounded-[30px] border border-white/[0.08] bg-black/20 backdrop-blur-[4px] w-[300px] sm:w-[350px] md:w-[400px] lg:w-[410px] h-auto flex-shrink-0 shadow-lg snap-center flex flex-col justify-between transition-transform duration-700 ease-out ${inView ? "animate-slideIn" : "opacity-0"
              }`}
          >
            {/* Image Section */}
            <img
              src={item.img}
              alt={item.title}
              className={` opacity-90 w-full ${item.title === "Super Buddy" ? "h-[124px] mt-3 -mb-4" : "mt-6 h-[100px]"
                } object-contain rounded-t-[30px]`}
            />


            {/* Content Section */}
            <div className="p-6 w-full flex-grow flex flex-col justify-between">
              <div>
                <h4 className="text-lg font-bold text-slate-50">{item.title}</h4>
                <p className="buddyText text-slate-500 text-sm my-4">
                  {item.description}
                </p>
              </div>
            </div>

            {/* Footer Section */}
            <div className="p-6 -mt-3 rounded-b-[30px] flex justify-between items-center">
              <span className="buddyText text-slate-500 text-sm">
                *This template is run {item.runs} times.
              </span>
              {!item.locked ? (
                <div
                  onClick={() => navigate(item.templatelink)}
                  className="button-3 w-fit cursor-pointer bg-gradient-to-r from-indigo-400 to-transparent bg-transparent ring-[#615fb0] ring-1 relative py-2 px-4 sm:py-2 sm:px-6 text-slate-300 rounded-full flex justify-center items-center shadow-md hover:shadow-lg"
                >
                  Run
                </div>
              ) : (
                <div
                  className="button-3 items-center text-center w-fit cursor-pointer bg-gradient-to-r from-indigo-400 to-transparent bg-transparent ring-[#615fb0] ring-1 relative py-2 px-4 sm:py-2 sm:px-6 text-slate-300 rounded-full flex justify-center items-center shadow-md hover:shadow-lg"
                  onClick={() => window.open("https://dashboard.faangai.com", "_blank")}
                >
                  <p className="text-[13px]">🔒 Unlock with 1000 coins</p>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>

      <div className="flex space-x-2 mt-4">
        {Array.from({ length: dotsLength }).map((_, index) => (
          <span
            key={index}
            className={`h-2 w-2 rounded-full ${currentCard === index ? "bg-slate-300" : "bg-slate-500"
              }`}
          />
        ))}
      </div>

      <div
        onClick={() => navigate("/templates")}
        className="button-4 w-fit mt-8 cursor-pointer bg-gradient-to-r from-indigo-400 to-transparent bg-transparent ring-[#615fb0] ring-1 relative py-4 px-6 sm:py-5 sm:px-8 text-slate-300 rounded-full flex justify-center items-center shadow-md hover:shadow-lg"
      >
        Explore more
        <div
          className="absolute top-[60%] left-[50%] transform -translate-x-1/2 -translate-y-1/2 rounded-full"
          style={{
            background:
              "radial-gradient(circle, rgba(119,116,213,0.3) 0%, rgba(16,18,23,0) 100%)",
            boxShadow: "0px 0px 100px 10px rgba(119,116,213,0.4)",
            backdropFilter: "blur(20px)",
          }}
        ></div>
      </div>
    </section>
  );
};

export default HomeTemplates;
