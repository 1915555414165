import React from "react";
import { Helmet } from "react-helmet";

const ScoopOnHiringDecisions = () => {
    return (
        <>
            {/* SEO Metadata */}
            <Helmet>
                <title>How Candidates Are Evaluated and Chosen: The Inside Scoop on Hiring Decisions</title>
                <meta
                    name="description"
                    content="Get an insider look at how candidates are evaluated during the hiring process. Learn how to prepare and stand out with Buddy’s Interview Prep."
                />
                <meta
                    name="keywords"
                    content="hiring decisions, candidate evaluation, job interview tips, interview preparation, Buddy’s Interview Prep"
                />
                <meta name="author" content="Tensai Muse" />
            </Helmet>

            {/* Blog Content */}
            <div className="bg-[#1d1b3d03] text-gray-200 min-h-screen px-4 py-12">
                {/* Header Section */}
                <header className="text-center mb-12">
                    <h1 className="text-3xl font-bold text-white">
                        How Candidates Are Evaluated and Chosen: The Inside Scoop on Hiring Decisions 🎯
                    </h1>
                    <p className="text-lg text-gray-400 mt-2">
                        Ever wonder what happens after you finish an interview? Let’s take a peek behind the curtain 👀.
                    </p>
                </header>

                {/* Main Content */}
                <div className="max-w-4xl mx-auto">
                    {/* Section 1 */}
                    <section className="mb-8">
                        <h2 className="text-2xl font-semibold text-[#d7d6ff] mb-4">
                            1. Initial Screening: The First Filter 🔍
                        </h2>
                        <p className="mb-4">
                            Before you even step into an interview, recruiters are already evaluating your resume,
                            cover letter, and portfolio. Here’s what they look for:
                        </p>
                        <ul className="list-disc list-inside space-y-2">
                            <li><strong>Relevant experience 🛠️</strong></li>
                            <li><strong>Professional presentation 📝</strong></li>
                            <li><strong>Cultural fit 🤝</strong></li>
                        </ul>
                        <p className="mt-4">This stage is about meeting the basic qualifications. You’ve got to check the right boxes to move forward ✅.</p>
                    </section>

                    {/* Section 2 */}
                    <section className="mb-8">
                        <h2 className="text-2xl font-semibold text-[#d7d6ff] mb-4">
                            2. The Interview: Where It Gets Real 🎙️
                        </h2>
                        <p className="mb-4">
                            Interviews are where they dig deeper. Here’s what hiring managers evaluate:
                        </p>
                        <ul className="list-disc list-inside space-y-2">
                            <li>Behavioral questions to assess problem-solving and adaptability 🔄.</li>
                            <li>Soft skills like communication, teamwork, and listening 👂.</li>
                            <li>Job-specific knowledge: Can you handle the technical demands? 🧠</li>
                            <li>Cultural fit: Will you mesh well with the team? 🌟</li>
                        </ul>
                    </section>

                    {/* Section 3 */}
                    <section className="mb-8">
                        <h2 className="text-2xl font-semibold text-[#d7d6ff] mb-4">
                            3. The Post-Interview Evaluation: Behind the Scenes 🧐
                        </h2>
                        <p className="mb-4">
                            After the interview, teams discuss your performance. Here’s what they analyze:
                        </p>
                        <ul className="list-disc list-inside space-y-2">
                            <li>Consistency: Did your story align with your resume? 📜</li>
                            <li>Interpersonal dynamics: How did you interact with everyone? 🤝</li>
                            <li>Follow-up: Did you send a thank-you note? 📧</li>
                        </ul>
                    </section>

                    {/* Section 4 */}
                    <section className="mb-8">
                        <h2 className="text-2xl font-semibold text-[#d7d6ff] mb-4">
                            4. Reference & Background Checks: Verifying Your Story 📞
                        </h2>
                        <p className="mb-4">
                            If you make it this far, they’ll check your references and verify your qualifications.
                        </p>
                        <ul className="list-disc list-inside space-y-2">
                            <li>Consistency with your interview 📝.</li>
                            <li>Work ethic and collaboration skills 💼.</li>
                        </ul>
                    </section>

                    {/* Section 5 */}
                    <section className="mb-8">
                        <h2 className="text-2xl font-semibold text-[#d7d6ff] mb-4">
                            5. Final Decision: The Balancing Act ⚖️
                        </h2>
                        <p className="mb-4">
                            It’s not always about the most qualified person but the one who fits best. They’ll weigh:
                        </p>
                        <ul className="list-disc list-inside space-y-2">
                            <li>Cultural contribution 🌱.</li>
                            <li>Adaptability 🔄.</li>
                            <li>Enthusiasm for the role 💡.</li>
                        </ul>
                    </section>

                    {/* Section 6 */}
                    <section className="mb-8">
                        <h2 className="text-2xl font-semibold text-[#d7d6ff] mb-4">
                            6. Offer & Negotiation: Closing the Deal 💼
                        </h2>
                        <p className="mb-4">
                            The final step is negotiating compensation and benefits. Be ready to communicate your
                            value confidently 💪.
                        </p>
                    </section>

                    {/* CTA Section */}
                    <section className="text-center">
                        <h2 className="text-2xl font-semibold text-[#d7d6ff] mb-4">
                            Ready to Stand Out in Your Next Interview? 🚀
                        </h2>
                        <p className="mb-4">
                            Buddy’s Interview Prep can help you refine your answers, practice behavioral questions, and boost your confidence 📈.
                        </p>
                        <button
                            className="bg-gradient-to-r from-[#5b57c4] to-[#4943a6] text-white py-3 px-6 rounded-lg shadow-lg hover:shadow-xl hover:scale-105 transition-transform duration-300 ease-in-out"
                            onClick={() => window.open('https://dashboard.faangai.com', '_blank')}
                        >
                            Start Preparing with Buddy
                        </button>

                    </section>
                </div>
            </div>
        </>
    );
};

export default ScoopOnHiringDecisions;
