import { useEffect, useRef, useState } from "react";
import "../CSS/buttons.css"
import gradient_background from "../../../assets/lottie/gradien_background.json"
import EWOR from "../../../assets/EWOR-logo.png"
import { FaDiscord, FaMedal } from 'react-icons/fa';
import { IoIosRocket } from "react-icons/io";
import { useInView } from 'react-intersection-observer';
import Lottie from "lottie-react";
import product_of_the_day from "../../../assets/product_of_the_day.png"



const TypewriterText = ({ text, speed = 200, pauseDuration = 4000 }) => {
  const [displayedText, setDisplayedText] = useState('');
  const [index, setIndex] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    let timer;

    if (isDeleting) {
      // Deleting the text
      timer = setTimeout(() => {
        setDisplayedText(prevText => prevText.slice(0, -1));
        if (displayedText === '') {
          setIsDeleting(false);
          setIndex(prevIndex => (prevIndex + 1) % text.length);
        }
      }, speed);
    } else {
      // Typing the text
      if (index < text.length) {
        timer = setTimeout(() => {
          setDisplayedText(text.slice(0, index + 1));
          setIndex(prevIndex => prevIndex + 1);
        }, speed);
      } else {
        // Pausing before starting to delete
        timer = setTimeout(() => {
          setIsDeleting(true);
        }, pauseDuration);
      }
    }

    return () => clearTimeout(timer);
  }, [displayedText, isDeleting, index, text, speed, pauseDuration]);

  return <span>{displayedText}</span>;
};


const HeaderComponent = () => {
  const { ref, inView } = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.1, // Trigger when 10% of the iframe is in view
  });

  return (
    <section className="w-full flex flex-col justify-center items-center">
      <div className="mx-auto my-12 flex gap-4 flex-wrap">
        {/* Badge Container */}
        {/* <div className="z-10 mt-1 relative flex cursor-pointer items-center overflow-hidden py-[0.7px] px-[0.5px]">
          <a
            href="https://www.producthunt.com/posts/faang"
            target="_blank"
            rel="noopener noreferrer"
            className="border border-white/[0.08] bg-black/20 backdrop-blur-[4px] rounded-[20px] space-x-6 relative py-2 px-5 text-slate-300 flex justify-center items-center p-px no-underline transition-all duration-400 ease-linear"
            style={{
              background:
                "#3a3857",
            }}
          >
            
            <div className="p-1 flex items-center justify-center w-12 h-12 bg-[white] rounded-full text-white text-lg shadow-md">
              <img src={product_of_the_day}/>
            </div>

           
            <div>
              <div className="text-xs font-normal text-gray-200 tracking-wide">
                PRODUCT HUNT
              </div>
              <div className="text-sm font-bold text-slate-200">
                #2 Product of the Day
              </div>
            </div>
          </a>
        </div> */}

        <a href="https://www.producthunt.com/posts/faang?embed=true&utm_source=badge-top-post-badge&utm_medium=badge&utm_souce=badge-faang" target="_blank"><img src="https://api.producthunt.com/widgets/embed-image/v1/top-post-badge.svg?post_id=492370&theme=light&period=daily" alt="FAANG - Build&#0032;your&#0032;custom&#0032;AI&#0032;interviewer&#0032;&#0034;Buddy&#0034; | Product Hunt" width="250" height="54" /></a>
      </div>
      <h1 className="landing-page-tile w-full text-slate-300 text-6xl -mt-2 text-wrap text-center font-bold leading-tight max-sm:text-3xl lg:w-[70%]">
        Build your custom AI Interviewer <br />
        "<TypewriterText text="Buddy" />"

      </h1>
      <span className="z-10">
        <svg
          width="105" // Reduced width by 25%
          height="90" // Reduced height by 25%
          viewBox="0 0 120 60"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <defs>
            <filter id="lightShadow" x="-50%" y="-50%" width="200%" height="200%">
              <feDropShadow dx="1" dy="2" stdDeviation="2" flood-color="rgba(0, 0, 0, 0.15)" />
            </filter>
          </defs>

          <rect x="0" y="28" width="20" height="33" rx="10" fill="#bbbbbb" filter="url(#lightShadow)" className="bar bar1"></rect>
          <rect x="25" y="20" width="20" height="44" rx="10" fill="#bbbbbb" filter="url(#lightShadow)" className="bar bar2"></rect>
          <rect x="50" y="16" width="20" height="56" rx="10" fill="#bbbbbb" filter="url(#lightShadow)" className="bar bar3"></rect>
          <rect x="75" y="22" width="20" height="43" rx="10" fill="#bbbbbb" filter="url(#lightShadow)" className="bar bar4"></rect>
        </svg>
      </span>

      <style>
        {`
          .bar {
            animation: pulse 0.5s infinite ease-in-out;
          }
          .bar1 { animation-delay: 0s; }
          .bar2 { animation-delay: 0.2s; }
          .bar3 { animation-delay: 0.3s; }
          .bar4 { animation-delay: 0.10s; }

          @keyframes pulse {
            0%, 100% {
              transform: scaleY(1);
            }
            50% {
              transform: scaleY(1.1);
            }
          }
        `}
      </style>

      <p className="landing-subtitle text-slate-400 text-center my-4 max-sm:text-xs animate-fade-in-up">
        <span className="block max-lg:inline">
          Create personalized interview experiences that adapt to your unique style and needs
        </span>
        <span className="block max-lg:inline">
          It empowers you to design faster than ever.
        </span>
      </p>

      <div style={{ marginTop: "24px" }} className="!mb-5 flex space-x-4">
        <div
          onClick={() => (window.location.href = "https://dashboard.faangai.com")}
          className="pulse-glow button-1 cursor-pointer bg-transparent ring-1 ring-[#615fb0] relative py-6 px-12 text-slate-300 text-xl rounded-30 flex justify-center items-center shadow-[0_0_70px_rgba(97,95,176,0.5)] hover:shadow-[0_0_70px_15px_rgba(97,95,176,0.5)] max-sm:py-4 max-sm:px-8 max-sm:text-sm"
        >
          <IoIosRocket className="mr-3 text-2xl" /> {/* Rocket icon */}
          Try our AI Interviewer
        </div>


        {/* <a href="https://discord.gg/yTEAEqXqJQ" target="_blank"
          style={{
            background: "radial-gradient(112.32% 83.8% at 50.41% 50%, rgba(0, 0, 0, 0) 0%, rgba(152, 195, 189, 0.1) 100%)"
          }}
          className="bg-black/20 backdrop-blur-[4px] button-2 flex justify-center items-center text-slate-300 border border-white/[0.5]  ring-0 py-4 px-8 rounded-30 max-sm:py-[10px] max-sm:px-[15px] max-sm:text-xs"
        >
          <FaDiscord className="mr-2 text-[22px]" />
          Discord
        </a> */}
      </div>








      <div className="flex items-center justify-center mx-auto my-12 flex gap-[16px] flex-wrap">
        <div className="relative flex cursor-pointer items-center overflow-hidden rounded-lg py-[0.7px] px-[0.5px]">
          <a href='https://www.producthunt.com/products/faang' target="_blank">
            <div style={{
              background: "radial-gradient(112.32% 83.8% at 50.41% 50%, rgba(0, 0, 0, 0) 0%, rgba(152, 195, 189, 0.1) 100%)"
            }}
              className=" border border-white/[0.08] bg-black/20 backdrop-blur-[4px] space-x-6 relative py-2 px-5 text-slate-300 rounded-lg flex justify-center items-center p-px no-underline transition-all duration-400 ease-linear">
              <div className="flex space-x-2 items-center">
                <div className="bg-[#FF6154] w-6 h-6 sm:w-8 sm:h-8 rounded-full text-slate-50 text-lg sm:text-2xl flex justify-center items-center font-semibold">
                  P
                </div>
                <div className="flex flex-col">
                  <span className="text-[8px] sm:text-[10px] font-bold">FEATURED ON</span>
                  <span className="text-base sm:text-lg font-bold">Product Hunt</span>
                </div>
              </div>
              <div className="flex flex-col justify-center items-center">
                <span>
                  <svg width="7" height="6" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M10.7774 9.28441H0.567383L5.67192 0.00512695L10.7774 9.28441Z" fill="white" />
                  </svg>
                </span>
                <span className="text-xs sm:text-sm">107</span>
              </div>
            </div>
          </a>
        </div>

        <div className="relative flex cursor-pointer items-center overflow-hidden rounded-lg py-[0.7px] px-[0.5px]">
          <div style={{
            background: "radial-gradient(112.32% 83.8% at 50.41% 50%, rgba(0, 0, 0, 0) 0%, rgba(152, 195, 189, 0.1) 100%)"
          }}
            className="w-full border border-white/[0.08] bg-black/20 backdrop-blur-[4px] space-x-6 relative py-2 px-5 text-slate-300 rounded-lg flex justify-center items-center p-px no-underline transition-all duration-400 ease-linear">
            <div className="flex space-x-2 items-center">
              <div className="bg-white w-6 h-6 sm:w-8 sm:h-8 rounded-full flex justify-center items-center">
                <img src={EWOR} className="w-full h-full" />
              </div>
              <div className="flex flex-col">
                <span className="text-[8px] sm:text-[10px] font-bold">Backed by</span>
                <span className="text-base sm:text-lg font-bold">EWOR</span>
              </div>
            </div>
          </div>
        </div>
        <a
          href="https://discord.gg/yTEAEqXqJQ"
          target="_blank"
          rel="noopener noreferrer"
          className="relative flex cursor-pointer items-center overflow-hidden rounded-lg py-[0.7px] px-[0.5px]"
        >
          <div
            style={{
              background: "radial-gradient(112.32% 83.8% at 50.41% 50%, rgba(0, 0, 0, 0) 0%, rgba(152, 195, 189, 0.1) 100%)",
            }}
            className="w-full border border-white/[0.08] bg-[#5060f2] backdrop-blur-[4px] space-x-6 relative py-2 px-5 text-slate-300 rounded-lg flex justify-center items-center p-px no-underline transition-all duration-400 ease-linear"
          >
            <div className="flex space-x-2 items-center">
              <div className="bg-[#5060f2] w-6 h-6 sm:w-8 sm:h-8 rounded-full flex justify-center items-center">
                <FaDiscord style={{ color: "white" }} className="text-[22px]" />
              </div>
              <div className="flex flex-col">
                <span className="text-[8px] sm:text-[10px] font-bold">Join Our</span>
                <span className="text-base sm:text-lg font-bold">Discord</span>
              </div>
            </div>
          </div>
        </a>

      </div>


      {/* <img className="soundwave" src={soundwaveImage} alt="Soundwave" /> */}
      {/* <iframe  style={{
          width: "100%",
          height: "600px",
          position: "absolute",
          top: "-2%",  // You might want to adjust this
          opacity: 0.4,
          border: "none", // Ensures no border around the iframe
        }}
        src="https://lottie.host/embed/dd412662-04d1-4c74-8558-c93d07d5c24d/4XQVDdefXr.json"></iframe> */}




      <Lottie
        animationData={gradient_background}
        loop={true}  // Set to true if you want it to loop
        style={{
          width: "100%",   // Match iframe width
          height: "600px", // Match iframe height
          position: "absolute",  // Match iframe positioning
          top: "-10%",  // Same adjustment as iframe
          opacity: 0.4,
          transition: 'opacity 3s ease-in-out',  // Same transition for smooth fading
          border: "none",  // Ensure no border (though not really needed for Lottie)
        }}
      />

    </section>
  );
};

export default HeaderComponent;
