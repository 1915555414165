import { useLocation } from "react-router-dom"; // Import useLocation for accessing location state
import { useEffect, useState } from "react";
import SuperHeroBuddy from "../TemplatesType/SuperHeroBuddy";
import CVBoosterBuddyFeedback from "../TemplatesType/CVBoosterBuddy";
import { GiphyFetch } from "@giphy/js-fetch-api";
import PersonalityRoaster from "../TemplatesType/PersonalityRoaster";

// Initialize GiphyFetch with the API key
const gf = new GiphyFetch("GNtZzNEZ05WNIX6OFYuXbAvMRVO5Kc3u");

const TemplatesProcessing = () => {
  const location = useLocation(); // Get location from useLocation
  const { templateType, response } = location.state || {}; // Extract state values from location

  // Extract superhero name for GIF search
  const superheroName = response?.superhero_match?.superhero_name;

  // Extract the text before the first parenthesis
  const imgGif = superheroName?.split(" (")[0];


  // States for storing GIF URLs and loading status
  const [gifUrl, setGifUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [staticImageUrl, setStaticImageUrl] = useState(null);

  // Function to fetch GIF from Giphy
  const fetchGif = async () => {
    setLoading(true);
    try {
      // Remove the word "the" from imgGif
      const modifiedImgGif = imgGif.replace(/\bThe\b/gi, '').trim();

      // Generate a random index (0, 1, or 2)
      const randomIndex = Math.floor(Math.random() * 3);

      const result = await gf.search(modifiedImgGif, { limit: 10, rating: "pg" }); // Fetch up to 10 GIFs
      console.log("result", result);

      if (result.data.length > 0) {
        // Choose the random GIF from the result
        setGifUrl(result.data[randomIndex].images.original.url); // Extract the random GIF's URL
        setStaticImageUrl(result.data[randomIndex].images.downsized_still.url);
      } else {
        setGifUrl(null); // Handle case where no GIF is found
        setStaticImageUrl(null);
      }
    } catch (error) {
      console.error("Error fetching GIF:", error);
    }

    setLoading(false);
  };

  // Fetch GIF when the component mounts or the superhero name changes
  useEffect(() => {
    if (imgGif) {
      fetchGif();
    }
  }, [imgGif]);

  // Render based on templateType
  if (templateType === "superbuddy") {
    return (
      <SuperHeroBuddy
        superheroData={response}
        gifUrl={gifUrl}
        staticImageUrl={staticImageUrl}
      />
    );
  }

  if (templateType === "personalityroaster") {
    return <PersonalityRoaster data={response} />;
  }


  return null; // Fallback for other cases
};

export default TemplatesProcessing;
