import React from "react";
import { useInView } from "react-intersection-observer";
import rihabPicture from "../../assets/rihab_picture.jpeg";
import badisPicture from "../../assets/badis_picture.jpg";

const Section = ({ children }) => {
  const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.15 });
  return (
    <div
      ref={ref}
      className={`transition-opacity duration-700 transform ${
        inView ? "opacity-100 translate-y-0" : "opacity-0 translate-y-8"
      }`}
    >
      {children}
    </div>
  );
};

const FAANGTeam = () => {
  const teamMembers = [
    {
      img: rihabPicture,
      name: "Rihab Lajmi",
      role: "Co-founder & CEO",
      description:
        "Ex-Microsoft and Google engineer, Rihab co-founded FAANG to help candidates excel in interviews. She actively supports job seekers on social media, making interview prep accessible to all.",
    },
    {
      img: badisPicture,
      name: "Badis Zormati",
      role: "Co-founder & CTO",
      description:
        "With a strong background in engineering, Badis leads FAANG’s AI development to create user-friendly and impactful interview tools, empowering candidates to perform at their best.",
    },
  ];

  return (
    <div className="max-w-[1340px] !pt-12  mx-auto py-16 px-6 lg:px-8 flex flex-col items-center">
      {/* Header Section */}
      <Section>
        <h1 className="text-[26px] sm:text-[36px] font-bold text-center mb-4 bg-gradient-to-r from-[#E1E1E1] to-[#929292] bg-clip-text text-transparent">
          Meet the FAANG Team
        </h1>
        <p className="text-[#d1d1d1] text-center text-[16px] max-w-[800px] mx-auto mb-12">
          We’re a team dedicated to transforming the interview experience.
        </p>
      </Section>

      {/* Team Members Section */}
      <div className="flex flex-wrap justify-center gap-12">
        {teamMembers.map((member, index) => (
          <Section key={index}>
            <div className="flex flex-col items-center text-center max-w-xs p-6 bg-[#1e1e2a] bg-opacity-60 rounded-xl shadow-md backdrop-blur-lg">
              <img
                src={member.img}
                alt={member.name}
                className="w-40 h-40 mb-4 rounded-full border-4 border-indigo-600 object-cover"
              />
              <h3 className="text-xl font-semibold text-white mb-1">{member.name}</h3>
              <p className="text-indigo-400 font-medium mb-4">{member.role}</p>
              <p className="text-[#d1d1d1] text-[14px] leading-relaxed">{member.description}</p>
            </div>
          </Section>
        ))}
      </div>
    </div>
  );
};

export default FAANGTeam;
