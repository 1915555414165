import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../index.css";
import faanglogo from "../../assets/faang_white_logo.png";

const Navbar = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(true);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const navigate = useNavigate();

  return (
    <div className="antialiased dark-mode:bg-gray-900">
      {/* Banner Section */}
      <div style={{ background: "linear-gradient(21deg, rgb(125 38 170) 33.59%, rgb(63 59 133) 59.88%, rgb(49 45 100) 87.87%)" }}
        className="underline w-full text-sm font-medium text-white text-center py-2">
        We raised our pre-seed round! 🎉
      </div>

      <div className="w-full text-gray-700 bg-white shadow-lg bg-opacity-0 backdrop-blur-lg dark-mode:text-gray-200 dark-mode:bg-gray-800">
        <div className="flex flex-col max-w-screen-xl px-4 mx-auto md:items-center md:justify-between md:flex-row md:px-4 lg:px-8">
          <div className="flex flex-row items-center justify-between p-4">
            <div
              className="text-lg font-semibold tracking-widest text-gray-900 uppercase rounded-lg dark-mode:text-white focus:outline-none focus:shadow-outline cursor-pointer"
              onClick={() => navigate("/")}
            >
              <div className="flex flex-col">
                <img
                  style={{ objectFit: "contain", height: "34px", marginTop: "2px" }}
                  src={faanglogo}
                  alt="Faang Logo"
                />
              </div>
            </div>
            <button
              className="rounded-lg md:hidden focus:outline-none focus:shadow-outline"
              onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
            >
              <svg fill="currentColor" viewBox="0 0 20 20" className="w-6 h-6">
                <path
                  className={!mobileMenuOpen ? "block" : "hidden"}
                  fillRule="evenodd"
                  d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
                  clipRule="evenodd"
                />
                <path
                  className={mobileMenuOpen ? "block" : "hidden"}
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </div>

          <nav
            className={`flex-col flex-grow p-4 ${mobileMenuOpen ? "flex" : "hidden"
              } md:flex md:justify-end md:items-center md:flex-row`}
          >
            <div
              className="link text-slate-100 px-4 py-2 mt-2 text-sm font-semibold bg-transparent rounded-lg cursor-pointer"
              onClick={() => navigate("/templates")}
            >
              <span className="text-original">Templates</span>
              <span className="text-new">Templates</span>
            </div>
            <div
              className="link text-slate-100 px-4 py-2 mt-2 text-sm font-semibold bg-transparent rounded-lg cursor-pointer"
              onClick={() => navigate("/career-page")}
            >
              <span className="text-original">Careers</span>
              <span className="text-new">Careers</span>
            </div>
            <div
              onClick={() => navigate("/aboutus")}
              className="link text-slate-100 px-4 py-2 mt-2 text-sm font-semibold bg-transparent rounded-lg"
            >
              <span className="text-original">About</span>
              <span className="text-new">About</span>
            </div>
            <div
              onClick={() => navigate("/blogs")}
              className="link text-slate-100 px-4 py-2 mt-2 text-sm font-semibold bg-transparent rounded-lg"
            >
              <span className="text-original">Blog</span>
              <span className="text-new">Blog</span>
            </div>

            <div
              onClick={() => navigate("/contactus")}
              className="link text-slate-100 px-4 py-2 mt-2 text-sm font-semibold bg-transparent rounded-lg"
            >
              <span className="text-original">Contact</span>
              <span className="text-new">Contact</span>
            </div>

            <div
              className={`flex items-center justify-between space-x-2 my-2 ml-2 ${mobileMenuOpen ? "mt-4" : "mt-0"
                }`}
            >
              <div
                onClick={() =>
                  (window.location.href = "https://dashboard.faangai.com")
                }
                className="flex justify-center bg-gradient-to-r from-indigo-400 to-transparent bg-transparent ring-[#3634e6] cursor-pointer ring-1 text-xs items-center py-2 px-4 text-white border rounded-3xl max-sm:text-xs max-sm:px-2"
              >
                Get Started
              </div>
              <div
                onClick={() =>
                (window.location.href =
                  "mailto:chat-with-founders@faanginc.com?subject=Inquiry&body=Hi there!")
                }
                className="flex justify-center items-center py-2 px-4 text-xs text-white ring-1 ring-slate-100 cursor-pointer rounded-3xl max-sm:text-xs max-sm:px-2"
              >
                Talk to Our Founders
              </div>
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
