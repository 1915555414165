import React, { useRef, useState } from "react";
import { FaPlay } from "react-icons/fa";
import { IoIosRocket } from "react-icons/io";
import { useInView } from 'react-intersection-observer';

const BuddyVideo = () => {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const { ref, inView } = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });

  const handlePlayPause = () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  return (
    <section
      ref={ref}
      className={`w-full mt-10 bg-cover bg-center flex items-center justify-center py-12 transition-all duration-700 ${inView ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`}
    >
      <div
        style={{
          borderRadius: '40px',
          border: '1px solid rgba(255, 255, 255, 0.08)',
          background: `
            radial-gradient(116.79% 78.42% at 41.61% 7.42%, rgba(217, 217, 217, 0.12) 0%, rgba(217, 217, 217, 0.02) 67.79%, rgba(217, 217, 217, 0) 100%),
            radial-gradient(55.92% 57.48% at 4.66% 41.21%, rgba(245, 245, 245, 0) 0%, rgba(54, 48, 141, 0.08) 45.36%, rgba(107, 99, 211, 0) 100%),
            rgba(19, 19, 27, 0.2)
          `,
          backdropFilter: 'blur(4px)'
        }}
        className="p-6 md:p-10 flex flex-wrap max-w-7xl w-full shadow-lg"
      >
        {/* Left Text Content */}
        <div className="w-full lg:w-1/3 p-4 md:p-8 text-left text-white space-y-4 md:space-y-6">
          <div
            className="w-fit flex items-center text-xs md:text-sm font-semibold bg-[#2d2d3a] px-2 md:px-3 py-1 rounded-md backdrop-blur-md"
            style={{
              borderRadius: '40px',
              border: '1px solid rgba(255, 255, 255, 0.08)',
              background: `
        radial-gradient(116.79% 78.42% at 41.61% 7.42%, rgba(217, 217, 217, 0.12) 0%, rgba(217, 217, 217, 0.02) 67.79%, rgba(217, 217, 217, 0) 100%),
        radial-gradient(55.92% 57.48% at 4.66% 41.21%, rgba(245, 245, 245, 0) 0%, rgba(54, 48, 141, 0.08) 45.36%, rgba(107, 99, 211, 0) 100%),
        rgba(19, 19, 27, 0.2)
      `,
              backdropFilter: 'blur(4px)'
            }}
          >
            <span className="mr-1" role="img" aria-label="rocket">🚀</span>
            Empower Your Interview Journey
          </div>
          <h1 className="text-2xl md:text-4xl font-bold leading-snug tracking-wide">
            Video with Buddy
          </h1>
          <p className="text-sm md:text-base font-light leading-relaxed text-gray-500">
            At FAANG, we're transforming interviews. Our AI-driven platform
            adapts to your strengths and areas for improvement, creating an
            engaging and empowering practice experience.
          </p>


          <a
            href="https://dashboard.faangai.com"
            target="_blank"
            rel="noopener noreferrer"
            className="w-fit pulse-button button-1 cursor-pointer bg-transparent ring-1 z-10 ring-[#615fb0] relative py-4 px-8 text-slate-300 rounded-30 flex justify-center items-center shadow-[0_0_50px_rgba(97,95,176,0.5)] hover:shadow-[0_0_50px_10px_rgba(97,95,176,0.5)] max-sm:py-[10px] max-sm:px-[15px] max-sm:text-xs"
          >
            <IoIosRocket className="mr-2" /> {/* Rocket icon */}
            Try our AI Interviewer

            <div
              className="bg-transparent z-30 rounded-30 absolute top-[60%] left-[50%] transform -translate-x-1/2 -translate-y-1/2"
              style={{
                background: "radial-gradient(circle, rgba(119,116,213,0.3) 0%, rgba(16,18,23,0) 100%)",
                boxShadow: "0px 0px 100px 10px rgba(119,116,213,0.4)",
                backdropFilter: "blur(20px)",
              }}
            ></div>
          </a>
        </div>


        {/* Right Video Content */}
        <div className="w-full lg:w-2/3 relative flex justify-center items-center rounded-2xl overflow-hidden group mt-6 lg:mt-0">
          {/* Overlay and Play button */}
          {!isPlaying && (
            <>
              <div className="absolute inset-0 bg-black opacity-30 z-10 transition-opacity duration-500 group-hover:opacity-50"></div>
              <button
                className="absolute z-20 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 p-3 bg-white rounded-full shadow-lg transition duration-300 hover:scale-110"
                onClick={handlePlayPause}
              >
                <FaPlay className="text-[#6460cf] text-lg md:text-xl" />
              </button>
            </>
          )}

          <video
            ref={videoRef}
            className="w-full h-full object-cover rounded-2xl cursor-pointer"
            src="https://video.wixstatic.com/video/09980f_bf4b065ccba94e58a8c2a63da333245d/480p/mp4/file.mp4"
            type="video/mp4"
            onClick={handlePlayPause}
            onEnded={() => setIsPlaying(false)}
          />
        </div>
      </div>
    </section>
  );
};

export default BuddyVideo;
