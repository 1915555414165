// import React, { useState } from "react";

// const BuildAgent = () => {
//   const [activeButton, setActiveButton] = useState("first");
//   const [selected, setSelected] = useState('Interview');

//   // Function to handle button click
//   const handleButtonClick = (button) => {
//     setActiveButton(button);
//   };

//   return (
//     <section className="w-full p-[0.5px] my-24 flex flex-col gap-[30px] mx-auto items-center justify-center">
//       <div className="conversationalButton relative flex rounded-full bg-gray-700">
//         <div
//           className={`absolute inset-0 w-1/2 rounded-full transition-transform duration-300 ${selected === 'Interview' ? 'translate-x-0' : 'translate-x-full'}`}
//           style={{ zIndex: 40, background: "linear-gradient(98deg, #5F5BCD -21.19%, rgba(48, 46, 103, 0.08) 103.46%)" }}
//         ></div>
//         <button
//           className={`relative px-6 py-3 rounded-full transition-all z-50 ${selected === 'Interview' ? 'text-white' : 'text-white'}`}
//           onClick={() => setSelected('Interview')}
//         >
//           Interview
//         </button>
//         <button
//           className={`relative px-6 py-3 rounded-full transition-all z-50 ${selected === 'Coffee' ? 'text-white' : 'text-white'}`}
//           onClick={() => setSelected('Coffee')}
//         >
//           Coffee
//         </button>
//       </div>
//       <div className="w-full max-w-[800px] mx-auto h-[350px] border-2 border-[#30325e] relative z-10 flex cursor-pointer overflow-hidden rounded-xl py-[1px] px-[1px]">
//         {/* <div className="animate-rotate absolute inset-0 rounded-full bg-[conic-gradient(#fff_20deg,transparent_120deg)]"></div> */}

//         <div className="w-full h-full bg-[#101217] relative py-3 px-7 text-slate-300 rounded-xl flex flex-col p-px no-underline transition-all duration-400 ease-linear">
//           <div className="relative flex flex-col w-full justify-center"></div>
//           {/* <div class="my-8 mx-auto w-[80%] h-[1px] bg-gradient-to-r from-slate-800 via-slate-100 to-slate-800"></div> */}
//           <div className="w-full h-full flex flex-col justify-center items-center gap-8 my-4">
//             <div className="w-full md:w-1/2 p-[0.5px] rounded-3xl flex flex-col justify-center items-center transition-all duration-400 ease-in-out relative overflow-hidden shadow-[0_0_50px_rgba(106,102,208,0.5)] hover:shadow-[0_0_50px_10px_rgba(106,102,208,0.5)]">
//               <div className="w-full mx-auto flex items-center justify-center">
//                 {/* <div className="w-full animate-rotate absolute inset-0 rounded-full bg-[conic-gradient(#fff_20deg,transparent_120deg)]"></div> */}
//                 <div className="w-full bg-[#101217] relative py-3 px-7 bg-gradient-to-r from-indigo-400 to-transparent bg-transparent ring-[#6A66D0] border-[#6A66D0] text-slate-300 rounded-3xl flex flex-col border justify-center items-center p-px no-underline transition-all duration-400 ease-linear text-center">
//                   Build Your Agent
//                 </div>
//               </div>
//             </div>
//             <div className="w-full md:w-1/2 text-slate-300 border-[0.5px] ring-0 py-3 px-8 rounded-3xl flex justify-center items-center text-center">
//               Test Buddy Template
//             </div>
//           </div>
//         </div>
//       </div>

//     </section>
//   );
// };

// export default BuildAgent;



import { useInView } from 'react-intersection-observer';
import React, { useState } from 'react';
import dashbord from '../../../assets/dashboardSS.png';
import Typewriter from 'typewriter-effect';
import { useNavigate } from 'react-router-dom';
import coffee_call_image from '../../../assets/coffee_call_image.png';
import soundwave_ai2 from '../../../assets/soundwave_ai2.png';
import soundwave_ai from '../../../assets/soundwave_ai.png';


import rocketimage from '../../../assets/rocket_image.png';
import coffee from '../../../assets/coffee.png';
import meteor from '../../../assets/meteor.png';





const BuildAgent = () => {
  const [selected, setSelected] = useState('Interview');
  const { ref, inView } = useInView({
    triggerOnce: true, // Trigger animation only once
    threshold: 0.1, // Trigger when 10% of the section is in view
  });

  const navigate = useNavigate();
  // Function to handle button click
  const handleButtonClick = (button) => {
    setSelected(button);
  };

  const icons = [
    { src: rocketimage, alt: 'Rocket' },
    { src: coffee, alt: 'Coffee' },
    { src: meteor, alt: 'Meteor' }
  ];

  return (
    <>
      <section
        ref={ref}
        style={{ marginBottom: "-10px" }}
        className={`flex flex-col lg:flex-row p-7 gap-5 w-full my-20 mx-auto items-center justify-center ${inView ? 'animate-slideIn' : 'opacity-0'}`}
      >
        {/* Card 1: AI Interview */}
        <div className="w-full max-w-lg lg:max-w-[800px] mx-auto h-auto relative z-10 flex cursor-pointer overflow-hidden rounded-xl">
          <div
            className="w-full h-full bg-gradient-to-r from-[rgb(31,31,39)] to-[rgba(48,46,103,0.08)] p-6 lg:p-[50px] text-slate-300 rounded-xl flex flex-col backdrop-blur-lg mx-auto mb-8 max-w-[1100px] border border-transparent"
            style={{
              borderRadius: "40px",
              border: "1px solid rgba(255, 255, 255, 0.08)",
              background: "radial-gradient(116.79% 78.42% at 41.61% 7.42%, rgba(217, 217, 217, 0.12) 0%, rgba(217, 217, 217, 0.02) 67.79%, rgba(217, 217, 217, 0.00) 100%), radial-gradient(55.92% 57.48% at 4.66% 41.21%, rgb(245 245 245 / 0%) 0%, rgb(54 48 141 / 8%) 45.36%, rgba(107, 99, 211, 0.00) 100%), #13131b33",
              // background: "radial-gradient(112.32% 83.8% at 50.41% 50%, rgba(0, 0, 0, 0) 0%, rgba(71, 72, 105, 0.53) 100%), rgba(0, 0, 0, 0.2)",
              backdropFilter: "blur(4px)",
            }}

          >
            {/* Heading Section */}
            <div className="flex flex-col items-center justify-center text-center mb-4">
              <img
                style={{
                  height: '111px',
                  width: '70%',
                  marginTop: '-12px',
                  marginBottom: '40px'
                }}
                src={soundwave_ai2}
                alt="Soundwave AI"
              />

              <p className="text-2xl lg:text-3xl font-semibold mb-2">
                {/* Assess Your Interview Skills */}
                Build your own AI coach
              </p>
              <p className="text-sm lg:text-base text-gray-500">
                {/* Evaluate your performance with instant feedback on responses, confidence, and problem-solving. */}
                Create your own custom Buddy! Whether you're prepping for interviews or just want a smart AI to chat with, your Buddy is ready to help you crush it and keep you company on any topic you choose!
              </p>
            </div>

            {/* Chart Section */}
            <div className="flex flex-col items-center justify-center mb-8">
              {/* <img
              src={dashbord}
              alt="Dashboard"
              className="w-4/5 h-auto mt-4 rounded-2xl shadow-xl"
            /> */}
            </div>

            {/* New Text Above Buttons */}
            <div className="text-center text-base lg:text-lg mb-6">
              <p>Make a 5-minute call with an AI Interviewer</p>
            </div>

            {/* Call to Action Section */}
            <div className="flex items-center justify-center">
              <div
                onClick={() => window.location.href = "https://dashboard.faangai.com"}
                className="button-1 cursor-pointer bg-transparent  z-10  relative py-3 px-6 text-slate-300 rounded-30 flex justify-center items-center hover:shadow-[0_0_50px_10px_rgba(97,95,176,0.5)] max-sm:py-[8px] max-sm:px-[12px] max-sm:text-xs"
                style={{ width: '70%' }}
              >
                {/* Get Started */}
                Try our AI Interviewer
                <div
                  className="bg-transparent z-30 rounded-30 absolute top-[60%] left-[50%] transform -translate-x-1/2 -translate-y-1/2"
                  style={{
                    background: "radial-gradient(circle, rgba(119,116,213,0.3) 0%, rgba(16,18,23,0) 100%)",
                    boxShadow: "0px 0px 100px 10px rgba(119,116,213,0.4)",
                    backdropFilter: "blur(20px)",
                  }}
                ></div>
              </div>
            </div>
          </div>
        </div>

        {/* Card 2: Coffee Call */}
        <div className="w-full max-w-lg lg:max-w-[800px] mx-auto h-auto relative z-10 flex cursor-pointer overflow-hidden rounded-xl">

          <div
            className="w-full h-full bg-gradient-to-r from-[rgb(31,31,39)] to-[rgba(48,46,103,0.08)] p-6 lg:p-[50px] text-slate-300 rounded-xl flex flex-col backdrop-blur-lg mx-auto mb-8 max-w-[1100px] border border-transparent"
            //         style={{
            //           backdropFilter: "blur(24px)",
            //           borderRadius: "32px",
            //           marginBottom: "30px",
            //           background: `
            //   linear-gradient(#0a0a0a, #020005) padding-box,
            //   linear-gradient(180deg, rgba(255, 255, 255, 0.13) -0.61%, rgba(255, 255, 255, 0.46) -0.43%, rgba(255, 255, 255, 0.2) 13.63%, rgba(255, 255, 255, 0.03) 100%) border-box
            // `
            //         }}
            style={{
              borderRadius: "40px",
              border: "1px solid rgba(255, 255, 255, 0.08)",
              background: "radial-gradient(116.79% 78.42% at 41.61% 7.42%, rgba(217, 217, 217, 0.12) 0%, rgba(217, 217, 217, 0.02) 67.79%, rgba(217, 217, 217, 0.00) 100%), radial-gradient(55.92% 57.48% at 4.66% 41.21%, rgb(245 245 245 / 0%) 0%, rgb(54 48 141 / 8%) 45.36%, rgba(107, 99, 211, 0.00) 100%), #13131b33",
              // background: "radial-gradient(112.32% 83.8% at 50.41% 50%, rgba(0, 0, 0, 0) 0%, rgba(71, 72, 105, 0.53) 100%), rgba(0, 0, 0, 0.2)",
              backdropFilter: "blur(4px)",
            }}

          >
            {/* Heading Section */}
            <div className="flex flex-col items-center justify-center text-center mb-4">
              <div className="flex justify-center space-x-6">
                {icons.map((icon, index) => (
                  <div
                    key={index}
                    style={{
                      marginBottom: "46px",
                      marginTop: "15px",
                      background: "radial-gradient(112.32% 83.8% at 50.41% 50%, rgba(0, 0, 0, 0) 0%, rgba(152, 195, 189, 0.1) 100%)"
                    }}
                    className="border border-white/[0.08] bg-black/20 backdrop-blur-[4px] w-20 h-20 flex items-center justify-center rounded-full shadow-lg"
                  >
                    <img src={icon.src} alt={icon.alt} className="w-10 h-10" />
                  </div>
                ))}
              </div>
              <p className="text-2xl lg:text-3xl font-semibold mb-2">
                {/* Make a Coffee Call */}
                Test our Buddy Templates
              </p>
              <p className="text-sm lg:text-base text-gray-500">
                {/* Discuss your career aspirations and get personalized advice over a casual coffee call. */}
                Explore our community-upvoted AI Buddy templates across areas like AI & Machine Learning, Data Science, Marketing and more. And for a bit of fun, check out our playful templates like Personality Roaster Buddy!
              </p>
            </div>

            {/* Chart Section */}
            <div className="flex flex-col items-center justify-center mb-8">
              {/* <img
              src={dashbord}
              alt="Dashboard"
              className="w-4/5 h-auto mt-4 rounded-2xl shadow-xl"
            /> */}
            </div>

            {/* New Text Above Buttons */}
            <div className="text-center text-base lg:text-lg mb-6">
              <p>Connect with us for a coffee call</p>
            </div>

            {/* Call to Action Section */}
            <div className="flex items-center justify-center">
              <div
                onClick={() => navigate('/templates')}
                className="button-1 cursor-pointer bg-transparent ring-0.5 z-10 ring-[#615fb0] relative py-3 px-6 text-slate-300 rounded-30 flex justify-center items-center  hover:shadow-[0_0_50px_10px_rgba(97,95,176,0.5)] max-sm:text-xs"
                style={{ width: '70%', background: "#00000000" }}
              >
                {/* Test Buddy Template */}
                Explore Templates
              </div>
            </div>
          </div>
        </div>
      </section>
    </>

  );
};

export default BuildAgent;

