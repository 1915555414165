import React from "react";
import bgimg from "../../../assets/bgStars.png";
import SVGTick from "../../../assets/SVG/SVGTick";

const HomePrice = () => {
  return (
    <section className="starsbg w-[70%] mx-auto mb-12 flex flex-col justify-center items-center max-sm:w-full max-lg:w-[95%]">
      <h1 className="landing-page-title-2 text-center-custom text-4xl text-slate-50 font-bold max-sm:text-2xl">
        0.2$ Per minute
      </h1>
      {/* <p className="text-[#9E9EAB] text-center my-4 max-sm:text-xs">
        Discover your superhero match upload your resume, click run, have a fun
        chat with Buddy to assess your behavioral skills and match you to e
        superhero
      </p> */}
      <div className="bg-[#e9e9e923] bg-opacity-0 backdrop-blur-md mx-auto px-8 py-2 my-4 rounded-xl shadow-[0_0_50px__5px_rgba(97,95,176,0.4)] flex justify-center items-center space-x-4 max-sm:p-2 max-sm:space-x-1">
        <div className="flex justify-center items-center">
          <div className="max-sm:hidden">
            <SVGTick />
          </div>
          <span className="text-slate-200 px-4 py-1 max-sm:text-[8px] max-sm:px-2 max-lg:text-xs">
            No Hidden Cost
          </span>
        </div>

        <div className="inline-block h-[10px] min-h-[1em] w-[0.5px] bg-slate-400"></div>
        <div className="flex items-center">
          <div className="max-sm:hidden">
            <SVGTick />
          </div>
          <span className="text-slate-200 px-4 py-1 max-sm:border-0 max-sm:py-2 max-sm:text-[8px] max-sm:px-2 max-lg:text-xs">
            No Subscription Required
          </span>
        </div>
        <div className="inline-block h-[10px] min-h-[1em] w-[0.5px] bg-slate-400"></div>

        <div className="flex items-center">
          <div className="max-sm:hidden">
            <SVGTick />
          </div>
          <span className="text-slate-200 px-4 py-1 max-sm:text-[8px] max-sm:px-2 max-lg:text-xs">
            Pay as you go
          </span>
        </div>
      </div>

      <div className="flex space-x-4 my-4">
        <div onClick={() => window.location.href = "mailto:chat-with-founders@faanginc.com?subject=Inquiry&body=Hi there!"} className="cursor-pointer bg-gradient-to-r from-indigo-400 to-transparent bg-transparent ring-[#615fb0] ring-1 relative py-3 px-8 text-slate-300 rounded-3xl flex justify-center items-center shadow-[0_0_50px__10px_rgba(97,95,176,0.5)] hover:shadow-[0_0_50px_20px_rgba(97,95,176,0.5)] max-sm:py-[10px] max-sm:px-[15px] max-sm:text-xs">
          {/* Book a Demo */}
          Chat with Founders
          <div
            className=" bg-transparent z-30 rounded-full absolute top-[60%] left-[50%] transform -translate-x-1/2 -translate-y-1/2"
            style={{
              background:
                "radial-gradient(circle, rgba(119,116,213,0.3) 0%, rgba(16,18,23,0) 100%)",
              boxShadow: "0px 0px 100px 10px rgba(119,116,213,0.4)",
              backdropFilter: "blur(20px)",
            }}
          ></div>
        </div>

        <a href="https://discord.gg/yTEAEqXqJQ" target="_blank" className="text-slate-300 border-[0.5px] ring-0 py-3 px-8 cursor-pointer rounded-3xl max-sm:py-[10px] max-sm:px-[15px] max-sm:text-xs">
          Join Discord
        </a>
      </div>
    </section>
  );
};

export default HomePrice;
