import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { FaFacebookF, FaTwitter, FaLinkedinIn } from "react-icons/fa"; // Import social icons
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  FacebookIcon,
} from "react-share";

import React from "react";
import { FaFacebookSquare, FaTwitterSquare, FaLinkedin } from "react-icons/fa"; // React Icons for buttons
import MetaTags from "../../../Components/MetaTags/MetaTags";
import { Helmet } from "react-helmet";
import { CSSTransition } from 'react-transition-group'; // For animations
import { MdWarning } from "react-icons/md";
import { FiX } from "react-icons/fi";
import coffeeCup from "../../../assets/TemplatesEmojis/13.png";
import fireball from "../../../assets/TemplatesEmojis/14.png";
import rocket from "../../../assets/TemplatesEmojis/16.png";
import outlineCoffeeCup from "../../../assets/TemplatesEmojis/17.png";
import feedbackPopup from "../../../assets/TemplatesEmojis/18.png";
import superheroBadge from "../../../assets/TemplatesEmojis/19.png";
import astronaut from "../../../assets/TemplatesEmojis/20.png";
import wowBubble from "../../../assets/TemplatesEmojis/21.png";
import brain from "../../../assets/TemplatesEmojis/22.png";
import sunglassesEmoji from "../../../assets/TemplatesEmojis/23.png";
import horoscope from "../../../assets/TemplatesEmojis/horoscope.png";
import business_idea from "../../../assets/TemplatesEmojis/business_idea.png";
import spirit from "../../../assets/TemplatesEmojis/10 2.png";




// import { FacebookShareButton, FacebookIcon } from 'react-share';

const SuperHeroBuddy = ({ superheroData, gifUrl, staticImageUrl }) => {
  const url = window.location.href;

  const { superhero_name, meme, backstory } = superheroData.superhero_match;
  const { details, social_media } = superheroData;

  const [openpopup, setOpenpopup] = useState(false)

  const handleShareChallenge = () => {
    // console.log("Sharing the superhero challenge!");
    setOpenpopup(true)
  };

  const handleSocialShare = (platform) => {
    const url = "https://faangai.com/templates/superherobuddy";
    const title = `Wow! My resume matches with  **${superhero_name}**.`; // Bold the superhero name
    const summary = "See what kind of superhero you are based on your skills!";
    const hashtags = "#FAANG #SuperBuddyMatch #FAANGAI #Buddy #AI_Interviewer";

    const fullText = `${title}.\n ${summary} \n ${hashtags} \n ${url}`;

    const theText = `Just found out which superhero matches my work personality thanks to SuperBuddy! Who’s yours? 🔥🦸‍♂️ Powered by @FAANG. Try it out for free! 🚀 #SuperBuddyMatch #FAANGAI #Buddy #AI_Interviewer\n ${url}`;

    const twittertext = `Just found out which superhero matches my work personality thanks to SuperBuddy! Who’s yours? 🔥🦸‍♂️ Powered by @FAANG. Try it out for free! 🚀 #SuperBuddyMatch #FAANGAI #Buddy #AI_Interviewer`;

    const shareUrls = {
      // Facebook: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}&quote=${encodeURIComponent(theText)}`, // Use quote for Facebook title
      Facebook: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}&quote=${encodeURIComponent(summary)}&hashtag=${encodeURIComponent(hashtags)}&href=${url}`,
      Twitter: `https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}&text=${encodeURIComponent(twittertext)}`,
      LinkedIn: `https://www.linkedin.com/shareArticle?mini=true&text=${encodeURIComponent(theText)}`, // Keep LinkedIn text separate for control
    };
    window.open(shareUrls[platform], "_blank", "noopener,noreferrer");
    setOpenpopup(false)
  };

  // const facebookshare = () = > {

  // }
  const modalRef = useRef();

  // Close the modal if clicked outside
  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setOpenpopup(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // https://www.facebook.com/share_channel/?type=reshare&link=https%3A%2F%2Ffaangai.com%2Ftemplates%2Fsuperherobuddy&app_id=966242223397117&source_surface=external_reshare&display&hashtag=%23SuperheroResume+%23CareerGoals
  return (
    <>
      {/* <MetaTags title={'My Resume Matches with a Superhero!'} description={'Check out which superhero I match with and what powers I possess!'} image={'https://faangai.com/static/media/faang_white_logo.525f9df782728ecd3604.png'} url={'https://faangai.com/templates/superherobuddy'} /> */}

      <Helmet>
        <title>My Resume Matches with a Superhero!</title>
        <meta property="og:title" content={`My Resume Matches with ${superhero_name}!`} />
        <meta property="og:description" content="Check out which superhero I match with and what powers I possess!" />
        <meta property="og:image" content="https://faangai.com/assets/faang_logo_sqaure.png" />
        <meta property="og:url" content="https://faangai.com/templates/superherobuddy" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Check Out Your Superhero Buddy!" />
        <meta name="twitter:description" content="See which superhero you resemble based on your resume!" />
        <meta name="twitter:image" content="https://faangai.com/assets/faang_logo_sqaure.png" />
      </Helmet>



      <div className="relative max-w-[1340px] h-auto mx-auto flex flex-col max-sm:p-[10px]">
        <div className="lg:w-[1038px] my-[50px] mx-auto flex flex-col gap-[50px] items-center">
          <div className="flex justify-center">
            <div className="inline-block flex flex-col gap-[40px] items-center">
              <h1 className="w-full relative text-3xl p-2 font-bold text-center bg-gradient-to-r from-[#E1E1E1] to-[#929292] bg-clip-text text-transparent flex items-center justify-center">
                <span className="text-nowrap">Your Superhero Match:</span>
                <div className="relative overflow-hidden flex">
                  <h2 className="rainbow-text text-nowrap">{superhero_name}</h2>
                </div>
              </h1>
              <img
                src={gifUrl}
                alt={`${superhero_name} meme`}
                className="w-[350px] h-[200px]"
              />
            </div>
          </div>


          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 justify-center px-6">
            {Object.keys(details).map((key, index) => (
              <div
                key={index}
                className="flex flex-col gap-6 p-6 md:p-8 bg-gradient-to-b from-[#6c68e42e] to-[#1f1d36] rounded-3xl shadow-lg hover:shadow-2xl w-[320px] md:w-[500px] relative transition-all duration-300"
              >
                <div className="flex justify-between items-center">
                  <h1 className="flex items-center font-normal text-xl text-transparent bg-clip-text bg-gradient-to-r from-indigo-300 to-purple-400">
                    {details[key].title === "Which Superhero Are You?" &&
                      <img src={superheroBadge} alt="rocket" className="inline-block w-11 h-11 mr-2" />
                    }
                    {details[key].title === "Your Next Big Adventure" &&
                      <img src={fireball} alt="rocket" className="inline-block w-10 h-10 mr-1" />
                    }
                    {details[key].title === "Your Horoscope" &&
                      <img src={horoscope} alt="Your Horoscope" className="inline-block h-10 mr-2" />
                    }
                    {details[key].title === "Bonus: Superhero Crossover Personality Test" &&
                      <span>🚀</span>
                    }
                    {details[key].title === "Your Spirit Animal" &&
                       <img src={spirit} alt="Your Horoscope" className="inline-block h-8 mr-2" />
                    }
                    {details[key].title === "Your Ideal Sidekick" &&
                      <img src={astronaut} alt="rocket" className="inline-block w-12 h-12 mr-1 -ml-4" />
                    }
                    {details[key].title === "Killer Business Idea" && 
                      <img src={business_idea} alt="Killer Business Idea" className="inline-block h-10 mr-2" />
                    }
                    {details[key].title === "Your Arch-Nemesis at Work" && <span>🚀</span>}
                    {details[key].title === "Guilty Pleasure" &&
                      <img src={wowBubble} alt="rocket" className="inline-block w-11 h-11 mr-2" />
                    }
                    {details[key].title === "AI's Superhero Roast" && <span>🚀</span>}
                    {details[key].title === "Your Weakness" &&
                      <img src={brain} alt="rocket" className="inline-block w-7 h-7 mr-2" />

                    }
                    {details[key].title === "Your Catchphrase" &&
                      <img src={sunglassesEmoji} alt="rocket" className="inline-block w-6 h-6 mr-2" />
                    }
                    {details[key].title}
                  </h1>

                  {/* Social Icons */}
                  <div className="flex gap-3 items-center">
                    <div className="p-2 bg-[#74729e] rounded-full hover:bg-blue-500 cursor-pointer transition-colors duration-300">
                      <FaFacebookF className="text-sm text-white" onClick={() => handleSocialShare("Facebook")} />
                    </div>
                    <div className="p-2 bg-[#74729e] rounded-full hover:bg-blue-400 cursor-pointer transition-colors duration-300">
                      <FaTwitter className="text-sm text-white" onClick={() => handleSocialShare("Twitter")} />
                    </div>
                    <div className="p-2 bg-[#74729e] rounded-full hover:bg-blue-600 cursor-pointer transition-colors duration-300">
                      <FaLinkedinIn className="text-sm text-white" onClick={() => handleSocialShare("LinkedIn")} />
                    </div>
                  </div>
                </div>

                <hr className="border-[#E9E9E9] opacity-30" />

                {/* Card Content */}
                <p className="text-[15px] text-gray-300 leading-relaxed">
                  {details[key].content}
                </p>
              </div>
            ))}
          </div>


          {social_media.shareable_boxes && (
            <div className="flex flex-col gap-6 mt-10">
              <div
                onClick={handleShareChallenge}
                className="button-1 cursor-pointer bg-transparent ring-1 z-10 ring-[#615fb0] relative py-4 px-10 text-slate-300 rounded-30 flex justify-center items-center shadow-[0_0_50px_rgba(97,95,176,0.5)] hover:shadow-[0_0_50px_10px_rgba(97,95,176,0.5)] max-sm:py-[10px] max-sm:px-[15px] max-sm:text-xs"
              >
                Share the Superhero Challenge
                <div
                  className="bg-transparent z-30 rounded-30 absolute top-[60%] left-[50%] transform -translate-x-1/2 -translate-y-1/2"
                  style={{
                    background:
                      "radial-gradient(circle, rgba(119,116,213,0.3) 0%, rgba(16,18,23,0) 100%)",
                    boxShadow: "0px 0px 100px 10px rgba(119,116,213,0.4)",
                    backdropFilter: "blur(20px)",
                  }}
                ></div>
              </div>
            </div>
          )}
        </div>
        {openpopup && (
          <CSSTransition
            in={true}
            appear={true}
            timeout={300}
            classNames="fade-scale"
            unmountOnExit
          >
            <div className="bg-black bg-opacity-80 fixed inset-0 z-50 flex items-center justify-center w-full h-screen overflow-hidden">
              <div
                ref={modalRef}
                className="bg-opacity-60 backdrop-filter backdrop-blur-lg bg-[#3c3b3b45] border border-[#2a2a2a] rounded-2xl shadow-2xl w-[600px] p-10 relative transform transition-all duration-300 scale-100"
              >
                {/* Close Button */}
                <div
                  className="absolute top-4 right-4 w-8 h-8 bg-gray-800 bg-opacity-75 rounded-full flex items-center justify-center cursor-pointer transition-transform duration-300 hover:bg-opacity-90 hover:scale-110 shadow-lg"
                  onClick={() => setOpenpopup(false)}
                  aria-label="Close"
                >
                  <FiX className="text-gray-200 hover:text-white text-lg" />
                </div>

                {/* Modal Content */}
                <div className="text-white flex flex-col items-center space-y-8">
                  <h2 className="text-center font-extrabold text-3xl text-transparent bg-clip-text bg-gradient-to-r from-indigo-300 to-purple-400">
                    Share Your Superhero Match!
                  </h2>
                  <p className="text-center text-base text-gray-300 leading-relaxed px-6">
                    Discover your superhero match and let your friends know who you resemble! 🚀
                  </p>

                  {/* Social Share Icons */}
                  <div className="flex gap-6 mt-6">
                    {/* Facebook */}
                    <div
                      className="w-[60px] h-[60px] ring-2 ring-gray-600 rounded-full bg-opacity-10 flex items-center justify-center cursor-pointer transition-all duration-300 hover:ring-gray-400 hover:bg-[#4267B2] hover:scale-110"
                      onClick={() => handleSocialShare("Facebook")}
                    >
                      <FaFacebookF className="text-[#4267B2] text-[25px] hover:text-white" />
                    </div>

                    {/* Twitter */}
                    <div
                      className="w-[60px] h-[60px] ring-2 ring-gray-600 rounded-full bg-opacity-10 flex items-center justify-center cursor-pointer transition-all duration-300 hover:ring-gray-400 hover:bg-[#1DA1F2] hover:scale-110"
                      onClick={() => handleSocialShare("Twitter")}
                    >
                      <FaTwitter className="text-[#1DA1F2] text-[25px] hover:text-white" />
                    </div>

                    {/* LinkedIn */}
                    <div
                      className="w-[60px] h-[60px] ring-2 ring-gray-600 rounded-full bg-opacity-10 flex items-center justify-center cursor-pointer transition-all duration-300 hover:ring-gray-400 hover:bg-[#0077B5] hover:scale-110"
                      onClick={() => handleSocialShare("LinkedIn")}
                    >
                      <FaLinkedinIn className="text-[#0077B5] text-[25px] hover:text-white" />
                    </div>
                  </div>


                  {/* Call to Action Button */}
                </div>
              </div>
            </div>
          </CSSTransition>
        )}


      </div>
    </>
  );
};

export default SuperHeroBuddy;
