import React, { useState } from "react";
import {
  Brain,
  Laugh,
  Code,
  Database,
  Cog,
  Rocket,
  BarChart2,
  Cloud,
  Lock,
} from "lucide-react";
import { motion } from "framer-motion"; // Import motion for animations
import { Helmet } from "react-helmet"; // Import Helmet for dynamic meta tags

import faang from "../../assets/lighticon.png";
import google from "../../assets/google.png";
import personalityRoaster from "../../assets/personality_roaster2.png";
import salaryNinja from "../../assets/salary_ninja3.png";
import buddyRocket from "../../assets/super_buddy.png";
import { useNavigate } from "react-router-dom";
import { MdOutlineTimer } from "react-icons/md";

const Templates = () => {
  const [activeTab, setActiveTab] = useState(1); // Default to Funny Templates
  const navigate = useNavigate();

  const tabs = [
    { id: 1, icon: null, image: faang, label: "FAANG Interviews", locked: false },
    { id: 2, icon: <Rocket />, label: "Funny Templates", locked: false },
    { id: 3, icon: <Code />, label: "Software", locked: true },
    { id: 4, icon: <Database />, label: "Data Science", locked: true },
    { id: 5, icon: <Cog />, label: "AI & ML", locked: true },
    { id: 6, icon: <Rocket />, label: "Finance", locked: true },
    { id: 7, icon: <BarChart2 />, label: "Marketing", locked: true },
    { id: 8, icon: <Brain />, label: "Consulting", locked: true },
    { id: 9, icon: <BarChart2 />, label: "Business", locked: true },
    { id: 10, icon: <Cloud />, label: "Cloud Computing", locked: true },
  ];

  const slugify = (text) => {
    return text.toLowerCase().replace(/[^a-z0-9]+/g, "");
  };

  const handleRunTemplate = (template) => {
    console.log("template", template);
  
    if (template.title === "Google’s Googliness") {
      // Serialize the template object into query parameters
      const queryParams = new URLSearchParams({ 
        id: template.id, 
        title: template.title, 
        from: "landingPage"
      }).toString();

      console.log("queryParams",queryParams)
  
      // Redirect with query parameters
      window.open(`http://dashboard.faangai.com/?${queryParams}`, '_blank');
      return;
    }
  
    if (!template.locked) {
      const slug = slugify(template.title);
      navigate(`/templates/${slug}`);
    } else {
      console.log("Template is locked.");
    }
  };
  

  const cards = [
    {
      id: "googlesgoogliness",
      category: 1, // Updated to FAANG Interviews
      imageIcon: google,
      title: "Google’s Googliness",
      description: "Showcase how you align with Google’s culture of innovation and teamwork.",
      difficulty: "Medium",
      time: "10m",
      locked: false,
      gradient: "from-[#eb4132b0] via-[#fcbe0061] to-[#3b79e6]",
      size: { height: 50, width: 50 },
    },
    {
      id: 'personalityroaster',
      category: 2,
      imageIcon: personalityRoaster,
      title: "Personality Roaster",
      description:
        "Drop your CV, and let Personality Roaster turn up the heat with a roast you won’t forget! 🔥",
      locked: false,
      gradient: "from-purple-800  to-indigo-900",
      size: { height: 70, width: 70 },
    },
    {
      id: 'superbuddy',
      category: 2,
      imageIcon: buddyRocket,
      title: "Super Buddy",
      description: "Discover your inner superhero and unleash your powers! 🚀",
      locked: false,
      gradient: "from-purple-800  to-indigo-900",
      size: { height: 70, width: 70 },
    },
    {
      id: 'salarynegotiation',
      category: 2,
      imageIcon: salaryNinja,
      title: "Salary Negotiation",
      description:
        "Unlock expert tips to master salary negotiations and land your dream compensation. Collect 1000 coins to access this feature! 🔒",
      locked: true,
      gradient: "from-purple-800  to-indigo-900",
      size: { height: 70, width: 70 },
    },
  ];

  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        when: "beforeChildren",
        staggerChildren: 0.1,
      },
    },
  };

  const cardVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  const tabVariants = {
    hover: {
      scale: 1.05,
      transition: {
        type: "spring",
        stiffness: 300,
      },
    },
  };

  return (
    <>
      <Helmet>
        <title>Mock Interviews | FaangAI</title>
        <meta name="description" content="Choose from 100+ expert-vetted interviews, get feedback, and land your dream job." />
      </Helmet>

      <motion.div
        className="min-h-screen text-gray-200 font-sans mb-[300px]"
        initial="hidden"
        animate="visible"
        variants={containerVariants}
      >
        <header className="text-center py-8">
          <h1 className="text-4xl font-extrabold">Mock Interviews</h1>
          <p className="text-gray-400 mt-2 text-lg">
            Choose from 100+ expert-vetted interviews, get feedback, and land your dream job.
          </p>
        </header>
        <hr className="border-b border-gray-700" />

        <nav className="flex overflow-x-auto whitespace-nowrap px-6 py-4 space-x-6 scrollbar-hide">
          {tabs.map((tab) => (
            <motion.div
              key={tab.id}
              className={`flex flex-col items-center p-4 w-32 rounded-lg cursor-pointer transition-transform transform ${
                tab.locked
                  ? "blur-sm cursor-not-allowed"
                  : "text-gray-400 hover:text-white hover:bg-[#ffffff12]"
              }`}
              onClick={() => !tab.locked && setActiveTab(tab.id)}
              whileHover={!tab.locked ? "hover" : ""}
              variants={tabVariants}
            >
              <div
                className="p-2 rounded-full flex items-center justify-center shadow-lg"
                style={
                  activeTab === tab.id
                    ? {
                        background: "linear-gradient(21deg, rgb(84 77 183) 33.59%, rgb(63, 59, 133) 59.88%, rgb(49, 45, 100) 87.87%)",
                        backdropFilter: "blur(10px)",
                        boxShadow:
                          "rgba(0, 0, 0, 0.1) 0px 4px 6px, rgba(0, 0, 0, 0.06) 0px 1px 3px",
                        border: "2px solid #4f46e5",
                      }
                    : {
                        background: "rgba(255, 255, 255, 0.1)",
                        backdropFilter: "blur(10px)",
                        boxShadow:
                          "0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.06)",
                        border: "0.5px solid rgba(255, 255, 255, 0.2)",
                      }
                }
              >
                {tab.locked ? (
                  <Lock className="text-gray-500" />
                ) : tab.image ? (
                  <img
                    src={tab.image}
                    alt={tab.label}
                    className="h-8 w-8 rounded-full object-cover"
                  />
                ) : (
                  <div className="p-1 text-2xl">{tab.icon}</div>
                )}
              </div>
              <span className="text-sm font-medium mt-2">{tab.label}</span>
            </motion.div>
          ))}
        </nav>

        <motion.div
          className="mt-0 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 px-6 py-8"
          variants={containerVariants}
        >
          {cards
            .filter((card) => card.category === activeTab)
            .map((card) => (
              <motion.div
                key={card.id}
                className="relative bg-[#2c2949] backdrop-blur-md border border-gray-700 rounded-2xl shadow-lg hover:shadow-2xl transition-shadow duration-300 p-4 flex flex-col text-white"
                style={{
                  boxShadow:
                    "0 8px 20px rgba(0, 0, 0, 0.2), 0 4px 10px rgba(255, 255, 255, 0.1)",
                }}
                variants={cardVariants}
                whileHover={{ scale: 1.02 }}
              >
                {/* Icon Section */}
                <div
                  className={`h-[170px] rounded-xl bg-gradient-to-br ${card.gradient} flex items-center justify-center mb-4 text-5xl p-20`}
                >
                  {card.category === 1 && (
                    <div
                      className="p-2 rounded-full flex items-center justify-center shadow-lg"
                      style={{
                        background: "#ffffff61", // Light translucent background
                        backdropFilter: "blur(10px)", // Frosted glass effect
                        boxShadow:
                          "0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.06)", // Subtle shadows for 3D effect
                        border: "0.5px solid rgba(255, 255, 255, 0.2)", // Adds a subtle outline for depth
                      }}
                    >
                      <img
                        src={card.imageIcon}
                        alt={card.title}
                        style={{
                          height: `${card.size.height}px`,
                          width: `${card.size.width}px`,
                        }}
                        className="rounded-full object-cover"
                      />
                    </div>
                  )}
                  {card.category === 2 && (
                    <div className="w-26 h-26 bg-[#525286a1] rounded-full flex items-center justify-center shadow-inner shadow-lg">
                      <img
                        src={card.imageIcon}
                        alt={card.title}
                        className={`object-contain ${
                          card.title === "Super Buddy"
                            ? "w-[85px] h-[91px] mr-2"
                            : "w-[95px] h-[95px] p-3"
                        }`}
                      />
                    </div>
                  )}
                </div>

                <div className="flex flex-col p-3 h-full">
                  {/* Title */}
                  <h2 className="text-lg font-bold text-gray-200 mb-3">
                    {card.title}
                  </h2>

                  {/* Description */}
                  <p className="text-gray-300 text-sm leading-relaxed mb-4 flex-grow">
                    {card.description}
                  </p>

                  {/* Difficulty and Time */}
                  {card.difficulty && card.time && (
                    <div className="flex justify-between items-center text-sm text-gray-400 mb-4">
                      <span className="flex items-center gap-1 text-[13px]">
                        <MdOutlineTimer className="text-indigo-300" size={20} />
                        {card.time}
                      </span>
                      <span
                        className={`text-[12px] px-3 py-1 rounded-full font-medium ${
                          card.difficulty === "Easy"
                            ? "bg-green-600 text-green-200"
                            : card.difficulty === "Medium"
                            ? "bg-yellow-600 text-yellow-200"
                            : "bg-red-600 text-red-200"
                        }`}
                      >
                        {card.difficulty}
                      </span>
                    </div>
                  )}

                  {/* Button */}
                  <div className="mt-auto">
                    {!card.locked ? (
                      <button
                        className="w-full bg-gradient-to-r from-indigo-500 to-blue-600 text-white font-semibold py-3 rounded-lg hover:from-blue-600 hover:to-teal-500 transition-all duration-300 ease-in-out shadow-lg hover:shadow-2xl transform hover:-translate-y-1 focus:outline-none"
                        onClick={() => handleRunTemplate(card)}
                      >
                        Run Template
                      </button>
                    ) : (
                      <div className="flex items-center justify-center text-red-400 font-semibold py-3">
                        🔒 Unlock with 1000 coins
                      </div>
                    )}
                  </div>
                </div>
              </motion.div>
            ))}
        </motion.div>
      </motion.div>
    </>
  );
};

export default Templates;
