import React, { useEffect, useRef, useState } from "react";
import "../CSS/font.css";
import image1 from '../../../assets/2.png'; // Update path as needed
import image2 from '../../../assets/4.png'; // Update path as needed
// import build_image from '../../../assets/build_image.png'; // Update path as needed



const images = [image1, image2, image1, image2];


const calcDynamicHeight = (objectWidth) => {
  const vw = window.innerWidth;
  const vh = window.innerHeight;
  return objectWidth - vw + vh + 130;
};

const HorizontalScroll = ({ children, stepper }) => {
  const [dynamicHeight, setDynamicHeight] = useState(0);
  const [translateX, setTranslateX] = useState(0);
  const [currentStep, setCurrentStep] = useState(0);
  const [progress, setProgress] = useState(0);
  const [selected, setSelected] = useState("Individual");
  const lineWidth =
    window.innerWidth < 640
      ? 30
      : window.innerWidth < 768
        ? 100
        : window.innerWidth < 1024
          ? 140
          : 200;
  const containerRef = useRef(null);
  const objectRef = useRef(null);

  useEffect(() => {
    const handleDynamicHeight = () => {
      const objectWidth = objectRef.current.scrollWidth;
      setDynamicHeight(calcDynamicHeight(objectWidth));
    };

    const handleScroll = () => {
      const offsetTop = -containerRef.current.offsetTop;
      const totalWidth = objectRef.current.scrollWidth;
      const cardWidth = totalWidth / stepper.length - 300;

      const stepIndex = Math.min(
        Math.floor(offsetTop / cardWidth + 1),
        stepper.length - 1
      );

      const positiveStepIndex = Math.abs(stepIndex);
      if (offsetTop) {
        setCurrentStep(positiveStepIndex);
      }

      const progressInStep = (offsetTop % cardWidth) / cardWidth;
      setProgress(Math.abs(progressInStep * 100));

      setTranslateX(offsetTop);
    };

    handleDynamicHeight();
    window.addEventListener("resize", handleDynamicHeight);
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("resize", handleDynamicHeight);
      window.removeEventListener("scroll", handleScroll);
    };
  }, [stepper]);

  console.log("selected", selected);

  return (
    <div
      style={{ height: `${dynamicHeight}px` }}
      className="relative min-h-screen w-full h-auto my-12"
    >
      <div
        ref={containerRef}
        className="sticky top-0 left-0 w-full overflow-hidden"
      >
        <div style={{
          marginTop: "141px",
        }}
          className="custom-margins-2 top-24 left-1/2 transform flex flex-col gap-6 items-center">
          <div className="z-50 flex flex-col items-center justify-center space-y-4 text-center text-white">
            <h2 className="landing-page-title-2 slide-up text-center text-center-custom">
              The Conversational AI Flow
            </h2>

            {/* <p>
              Upload Your Resume, click run, have a fun chat with buddy to access your behaviour
              skills and match you to be superhero
            </p> */}
            {/* <div className="text-slate-400 text-center max-sm:text-xs flex items-center justify-center my-4">
              <div className="conversationalButton relative flex rounded-full bg-gray-700">
                <div
                  className={`absolute inset-0 w-1/2 bg-[#5F5BCD] rounded-full transition-transform duration-300 ${selected === "Individual"
                    ? "translate-x-0"
                    : "translate-x-full"
                    }`}
                  style={{ zIndex: 40 }}
                ></div>
                <button
                  className={`relative px-4 py-2 rounded-full transition-all z-50 ${selected === "Individual" ? "text-white" : "text-white"
                    }`}
                  onClick={() => setSelected("Individual")}
                >
                  Individual
                </button>
                <button
                  className={`relative px-4 py-2 rounded-full transition-all z-50 ${selected === "Recruiter" ? "text-white" : "text-white"
                    }`}
                  onClick={() => setSelected("Recruiter")}
                >
                  Recruiter
                </button>
              </div>
            </div> */}
          </div>

          <div className="flex items-center justify-center custom-margins max-sm:text-xs">
            {stepper.map((step, index) => (
              <div key={index} className="flex items-center space-x-2 mb-4">
                <div
                style={{
                  borderRadius: "2rem",
                  height: "auto",
                  textDecoration: "none",
                  boxShadow: "inset 0 0 12px #00000014",
                  fontSize: "10px sm:text-xs lg:text-sm", // Adjusted for various screen sizes
                  background: "radial-gradient(112.32% 83.8% at 50.41% 50%, rgba(0, 0, 0, 0) 0%, rgba(152, 195, 189, 0.1) 100%)"
                }}
                  className={`
                      text-center backdrop-blur-[4px] bg-gradient-to-b from-[#00000000] to-[#ffffff0a] border border-[#f2ffee14]
                      w-[3rem] sm:w-[4rem] md:w-[5rem]
                      mx-auto 
                      py-2 px-4 sm:px-6 
                      no-underline 
                      transition-all duration-400 ease-[cubic-bezier(.215,.61,.355,1)] 
                      
                      shadow-inner 
                      shadow-[#00000014]
                      flex 
                      justify-center 
                      font-light 
                      items-center 
                      ${index === currentStep
                      ? "!bg-[#5F5BCD] text-white border-none"
                      : "text-white"
                    }
                      `}
                 
                >
                  {step}
                </div>

                {index < stepper.length - 1 && (
                  <div
                    style={{
                      width: `${lineWidth}px`,
                      height: "0.5px",
                      background: `linear-gradient(to right, white ${index === currentStep
                        ? progress
                        : index < currentStep
                          ? 100
                          : 0
                        }%, #262a30 ${index === currentStep
                          ? progress
                          : index < currentStep
                            ? 100
                            : 0
                        }%)`,
                    }}
                  />
                )}
              </div>
            ))}
          </div>
        </div>

        <div
          ref={objectRef}
          className="w-full mt-4 max-sm:mt-14"
          style={{
            height: "62vh", // 79% of viewport height
            transform: `translateX(${translateX}px)`,
            willChange: "transform",
            zIndex: 10,
          }}
        >
          {children}
        </div>
      </div>
    </div >
  );
};

const ConversationFlow = () => {
  return (
    <section className="relative w-full my-6">
      <HorizontalScroll stepper={["Build", "Run", "Evaluate", "Share"]}>
        <div className="w-full h-full pl-[40px] flex items-center">

          {/* Build Card */}
          <div className="h-[300px] w-[630px] rounded-2xl bg-[#1a1c20] mr-[75px] flex-shrink-0 flex items-center p-6" style={{
            backdropFilter: 'blur(24px)',
            borderRadius: '24px',
            background: 'linear-gradient(rgb(22 22 38), rgb(15 11 28)) padding-box padding-box, linear-gradient(rgba(255, 255, 255, 0.13) -0.61%, rgba(255, 255, 255, 0.46) -0.43%, rgba(255, 255, 255, 0.2) 13.63%, rgba(255, 255, 255, 0.03) 100%) border-box border-box',
            border: '1px solid transparent'
          }}>
            <div className="text-white w-[70%] pr-4">
              <h2 className="text-3xl font-bold mb-2 text-[#d5d5d5]">Build</h2>
              <p className="text-[14px] font-thin text-gray-400 mb-4">
                Build your AI interview Agent whether to practice for a mock job interview or to get tips in a chill coffee call with Buddy
              </p>
              <ul className="list-none text-sm font-light text-white">

                <li className="flex items-center text-[15px] text-[#c2c3c4] mb-2">
                  <span className="text-xl mr-2">✔️</span> Rehearse for a job interview with real-time conversational AI
                </li>
                <li className="flex items-center text-[15px] text-[#c2c3c4] mb-2">
                  <span className="text-xl mr-2">✔️</span> Single-prompt AI agent
                </li>
                <li className="flex items-center text-[15px] text-[#c2c3c4]">
                  <span className="text-xl mr-2">✔️</span> Explore AI interviewer templates
                </li>
              </ul>
            </div>
            <div>
              {/* <img src={build_image}/> */}
            </div>
          </div>

          {/* Run Card */}
          <div className="h-[300px] w-[630px] rounded-2xl bg-[#1a1c20] mr-[75px] flex-shrink-0 flex items-center p-6" style={{
            backdropFilter: 'blur(24px)',
            borderRadius: '24px',
            background: 'linear-gradient(rgb(22 22 38), rgb(15 11 28)) padding-box padding-box, linear-gradient(rgba(255, 255, 255, 0.13) -0.61%, rgba(255, 255, 255, 0.46) -0.43%, rgba(255, 255, 255, 0.2) 13.63%, rgba(255, 255, 255, 0.03) 100%) border-box border-box',
            border: '1px solid transparent'
          }}>      <div className="text-white w-[70%] pr-4 h-full">
              <h2 className="text-3xl font-bold mb-2 text-[#d5d5d5]">Run</h2>
              <p className="text-[15px] font-thin text-gray-400 mb-4">
                Test the AI interviewer you built
              </p>
              <ul className="list-none text-sm font-light text-white">

                <li className="flex items-center text-[15px] text-[#c2c3c4] mb-2">
                  <span className="text-xl mr-2">✔️</span> Interact with Buddy
                </li>
                <li className="flex items-center text-[15px] text-[#c2c3c4] mb-2">
                  <span className="text-xl mr-2">✔️</span> Custom adjustment of the agent’s settings
                </li>
                <li className="flex items-center text-[15px] text-[#c2c3c4] mb-2">
                  <span className="text-xl mr-2">✔️</span> Test the AI interviewer before starting the call
                </li>
                <li className="flex items-center text-[15px] text-[#c2c3c4]">
                  <span className="text-xl mr-2">✔️</span> Cloud-based AI interviewer
                </li>
              </ul>
            </div>
          </div>

          {/* Evaluate Card */}
          <div className="h-[300px] w-[630px] rounded-2xl bg-[#1a1c20] mr-[75px] flex-shrink-0 flex items-center p-6" style={{
            backdropFilter: 'blur(24px)',
            borderRadius: '24px',
            background: 'linear-gradient(rgb(22 22 38), rgb(15 11 28)) padding-box padding-box, linear-gradient(rgba(255, 255, 255, 0.13) -0.61%, rgba(255, 255, 255, 0.46) -0.43%, rgba(255, 255, 255, 0.2) 13.63%, rgba(255, 255, 255, 0.03) 100%) border-box border-box',
            border: '1px solid transparent'
          }}>      <div className="text-white w-[70%] pr-4 h-full">
              <h2 className="text-3xl font-bold mb-2 text-[#d5d5d5]">Evaluate</h2>
              <p className="text-[15px] font-thin text-gray-400 mb-4">
                Generate assessment report from your interview
              </p>
              <ul className="list-none text-sm font-light text-white">

                <li className="flex items-center text-[15px] text-[#c2c3c4] mb-2">
                  <span className="text-xl mr-2">✔️</span> Assessment report based on profile
                </li>
                <li className="flex items-center text-[15px] text-[#c2c3c4] mb-2">
                  <span className="text-xl mr-2">✔️</span> Psychometric analysis from interview
                </li>
                <li className="flex items-center text-[15px] text-[#c2c3c4] mb-2">
                  <span className="text-xl mr-2">✔️</span> Language assessment
                </li>
                <li className="flex items-center text-[15px] text-[#c2c3c4]">
                  <span className="text-xl mr-2">✔️</span> Feedback generated by Buddy to the interview answers
                </li>
              </ul>
            </div>
          </div>

          {/* Share Card */}
          <div className="h-[300px] w-[630px] rounded-2xl bg-[#1a1c20] mr-[75px] flex-shrink-0 flex items-center p-6" style={{
            backdropFilter: 'blur(24px)',
            borderRadius: '24px',
            background: 'linear-gradient(rgb(22 22 38), rgb(15 11 28)) padding-box padding-box, linear-gradient(rgba(255, 255, 255, 0.13) -0.61%, rgba(255, 255, 255, 0.46) -0.43%, rgba(255, 255, 255, 0.2) 13.63%, rgba(255, 255, 255, 0.03) 100%) border-box border-box',
            border: '1px solid transparent'
          }}>      <div className="text-white w-[70%] pr-4 h-full">
              <h2 className="text-3xl font-bold mb-2 text-[#d5d5d5]">Share</h2>
              <p className="text-[14px] font-thin text-gray-400 mb-4">
                Share the outcomes and results of your interview.
              </p>
              <ul className="list-none text-sm font-light text-white">

                <li className="flex items-center text-[15px] text-[#c2c3c4] mb-2">
                  <span className="text-xl mr-2">✔️</span> Share assessment reports
                </li>
                <li className="flex items-center text-[15px] text-[#c2c3c4] mb-2">
                  <span className="text-xl mr-2">✔️</span> Showcase interview results
                </li>
                <li className="flex items-center text-[15px] text-[#c2c3c4]">
                  <span className="text-xl mr-2">✔️</span> Highlight improvements
                </li>
              </ul>
            </div>
          </div>

        </div>
      </HorizontalScroll>

    </section>

  );
};
export default ConversationFlow;
