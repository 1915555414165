import { useEffect, useRef, useState } from "react";
import "../../index.css";
import HeaderComponent from "./Components/HeaderComponent";
import BuildAgent from "./Components/BuildAgent";
import SuperHero from "./Components/SuperHero";
import HomeTemplates from "./Components/HomeTemplates";
import ConversationFlow from "./Components/ConversationFlow";
import OurPartner from "./Components/OurPartner";
import BuddyVideo from "./Components/BuddyVideo";
import HappyClients from "./Components/HappyClients/HappyClients";
import HomePrice from "./Components/HomePrice";
import DashboardDemo from "./Components/DashboardDemo";


// GNtZzNEZ05WNIX6OFYuXbAvMRVO5Kc3u
const Home = () => {



  return (
    <>
      <head>
        <title>Faang AI - AI Interviewer</title>
        <meta
          name="description"
          content="Faang AI - The AI Interviewer that helps you prepare for job interviews with precision and ease."
        />
        <meta name="keywords" content="FAANG AI, artificial intelligence, build agents, AI solutions, conversation flow, business optimization" />
        <meta name="author" content="FAANG AI" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="robots" content="index, follow" />

        <meta property="og:title" content="Faang AI - AI Interviewer" />
        <meta
          property="og:description"
          content="Faang AI - The AI Interviewer that helps you prepare for job interviews with precision and ease."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.faangai.com/" />
        <meta property="og:image" content="https://www.faangai.com/assets/faang_logo.png" />
        <meta property="og:image:alt" content="Welcome to FAANG AI - Revolutionizing AI Solutions" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
      </head>

      <div
        className="max-w-[1340px] h-auto  mx-auto flex flex-col max-sm:p-[10px]">

        {/* <iframe src="https://giphy.com/embed/45fvNo8yvdKXpybkcr" width="480" height="259" style={{}} frameBorder="0" class="giphy-embed" allowFullScreen></iframe><p><a href="https://giphy.com/embed/45fvNo8yvdKXpybkcr">via GIPHY</a></p> */}
        {/* <iframe src="https://giphy.com/embed/1M4RG1BsJYwSY" width="480" height="259" style={{}} frameBorder="0" class="giphy-embed" allowFullScreen></iframe><p><a href="https://giphy.com/gifs/1M4RG1BsJYwSY">via GIPHY</a></p> */}

        <HeaderComponent />
        <div class="my-20 w-[80%] mx-auto h-[1px] bg-gradient-to-r from-slate-800 via-slate-100 to-slate-800"></div>
        <HomeTemplates />
        <HappyClients />
        <OurPartner />
        <DashboardDemo />
        <BuildAgent />
        <BuddyVideo />
        <div class="my-24 w-[80%] mx-auto h-[1px] bg-gradient-to-r from-slate-800 via-slate-100 to-slate-800"></div>
        <SuperHero />
        <div class="my-10 -mb-[40px] w-[80%] mx-auto h-[1px] bg-gradient-to-r from-slate-800 via-slate-100 to-slate-800"></div>
        <ConversationFlow />
        <HomePrice />
      </div>

    </>


  );
};

export default Home;
