import React from "react";
import { useNavigate } from "react-router-dom";
import { motion, useAnimation } from "framer-motion";
import { Helmet } from "react-helmet";
import { useInView } from "react-intersection-observer";
import virtual_interview_header_image from "../../assets/BlogPost/virtual_interviews.png";
import soft_skills_header from "../../assets/BlogPost/soft_skills_interviews.png";
import quick_prep_header from "../../assets/BlogPost/prepare_interviews.png";
import hate_job_interviews from "../../assets/BlogPost/hate_job_interviews.png";
import inside_scoop from "../../assets/BlogPost/inside_scoop.png";

const blogPosts = [
  {
    id: 1,
    route: "ace-your-virtual-interview",
    title: "How to Stand Out in Virtual Interviews: A Guide for Remote Job Seekers",
    summary:
      "Master virtual interviews with Buddy's 'Communication Judge Buddy'—your guide to standing out and making a lasting impression in today's digital job market.",
    image: virtual_interview_header_image,
    date: "October 24, 2024",
  },
  {
    id: 2,
    route: "why-you-shouldnt-undersell-your-soft-skills",
    title: "Why You Shouldn’t Undersell Your Soft Skills in Interviews",
    summary:
      "Discover why soft skills are essential for interviews and how to showcase them effectively using STAR stories. Level up with Buddy's Skill Sculptor Buddy!",
    image: soft_skills_header,
    date: "November 20, 2024",
  },
  {
    id: 3,
    route: "how-to-prepare-for-interviews-when-you-dont-have-much-time",
    title: "How to Prepare for Interviews When You Don’t Have Much Time",
    summary:
      "Learn how to ace last-minute interviews with strategic prep tips like focusing on job descriptions, perfecting your pitch, and using Buddy’s Ace Your Interview Buddy.",
    image: quick_prep_header,
    date: "November 20, 2024",
  },
  {
    id: 4,
    route: "why-do-most-candidates-hate-job-interviews",
    title: "Why Do Most Candidates Hate Job Interviews?",
    summary:
      "Discover why candidates dread job interviews and learn how to tackle common challenges with effective strategies and Buddy’s Ace Your Interview Buddy.",
    image: hate_job_interviews,
    date: "November 20, 2024",
  },
  {
    id: 5,
    route: "how-candidates-are-evaluated-and-chosen",
    title: "How Candidates Are Evaluated and Chosen: The Inside Scoop on Hiring Decisions",
    summary:
      "Take a look behind the curtain to see how hiring decisions are made and learn how to improve your chances with Buddy’s Interview Prep.",
    image: inside_scoop,
    date: "November 20, 2024",
  },
];

const BlogPage = () => {
  const navigate = useNavigate();

  const handleReadMore = (route) => {
    navigate(`/blogs/${route}`);
  };

  return (
    <>
      <Helmet>
        <title>Blogs | Explore Career Tips & Insights with FAANGAI</title>
        <meta
          name="description"
          content="Browse our collection of blogs for actionable insights, expert tips, and industry knowledge. Elevate your tech career with FAANGAI's curated guides and resources."
        />
      </Helmet>

      <motion.div
        className="!pt-12 max-w-[1340px] mx-auto py-16 px-6 lg:px-8"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, ease: "easeOut" }}
      >
        <h1 className="text-[26px] font-bold text-center mb-4 sm:text-[36px] bg-gradient-to-r from-[#E1E1E1] to-[#929292] bg-clip-text text-transparent">
          Welcome to Our Blog
        </h1>
        <p className="text-center text-[#d1d1d1] text-[16px] mb-12">
          Stay updated with the latest insights, tips, and industry trends.
        </p>

        <div className="space-y-8">
          {blogPosts.map((post) => (
            <FadeUpCard key={post.id} post={post} onReadMore={handleReadMore} />
          ))}
        </div>
      </motion.div>
    </>
  );
};

const FadeUpCard = ({ post, onReadMore }) => {
  const controls = useAnimation();
  const [ref, inView] = useInView({ triggerOnce: true, threshold: 0.2 });

  React.useEffect(() => {
    if (inView) {
      controls.start({ opacity: 1, y: 0 });
    }
  }, [controls, inView]);

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, y: 50 }}
      animate={controls}
      transition={{ duration: 0.8, ease: "easeOut" }}
      className="flex flex-col md:flex-row bg-gradient-to-br from-[#7A77CC1A] to-[#7a77cc3b] backdrop-blur-lg p-6 rounded-xl shadow-md hover:shadow-lg transition-shadow duration-300"
    >
      {/* Image Section */}
      <div className="p-10 flex justify-center items-center w-full md:w-1/3 h-64 overflow-hidden rounded-lg mb-4 md:mb-0 md:mr-6">
        <img
          src={post.image}
          alt={post.title}
          className="w-full object-cover"
        />
      </div>

      {/* Content Section */}
      <div className="flex flex-col justify-between flex-grow">
        <div>
          <h2 className="text-2xl font-semibold text-white mb-2">
            {post.title}
          </h2>
          <p className="text-sm text-gray-400 mb-4">
            {post.date}
          </p>
          <p className="text-gray-300">{post.summary}</p>
        </div>

        {/* Right-Aligned Button */}
        <div className="mt-4 md:mt-0 flex justify-end">
          <button
            onClick={() => onReadMore(post.route)}
            className="bg-indigo-500 text-white font-semibold py-2 px-4 rounded-full shadow-md hover:bg-indigo-600 transition-all duration-300"
          >
            Read More
          </button>
        </div>
      </div>
    </motion.div>
  );
};

export default BlogPage;
